import React, { useCallback } from 'react';
import { Button, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { balanceGenerateCSV } from '../../../Reducers/balance';

function CSVButton({ style, className }) {
  const isLoading = useSelector(
    (state) => state.balance.generateCSV.isLoading,
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(balanceGenerateCSV()).catch((error) => {
      if (error.includes('403 Forbidden')) {
        return message.error('Status 403 Forbidden');
      }
      message.error(error.message || error);    });
  }, [dispatch]);

  return (
    <Button
      className={className}
      icon="file-excel"
      size="large"
      style={style}
      onClick={handleClick}
      loading={isLoading}
    >
      Get CSV invoice
    </Button>
  );
}

CSVButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

CSVButton.defaultProps = {
  style: null,
  className: '',
};

export default React.memo(CSVButton);
