import { Popover, Tooltip } from 'antd';
import React from 'react';
import ChangeBalanceForm from '../../ChangeBalanceForm';
import Icon from '@ant-design/icons/lib/components/Icon';
import { MinusCircleOutlined } from '@ant-design/icons';

function DecreaseBalance({ id, className }) {
  return (
    <Tooltip placement="bottomRight" title="Decrease balance">
      <Popover
        content={<ChangeBalanceForm type="decrease" id={id} />}
        title="Decrease balance"
        trigger="click"
        placement="topRight"
      >
        <MinusCircleOutlined />
        {/* <Icon type="minus-circle" className={['mr', className, 'cursor-pointer']} /> */}
      </Popover>
    </Tooltip>
  );
};

export default DecreaseBalance;
