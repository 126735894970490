import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Select, message } from 'antd';
import PropTypes from 'prop-types';
import { setCurrentCustomerToLS, getCurrentCustomerFromLS} from '../../Services/CoreService'
import { coreFetchCustomers, coreSetCurrentCustomer} from '../../Reducers/core';

/** Displays the user name */
function UserName({ className, customers }) {
  const payload = useSelector((state) => state.core.customers.payload, shallowEqual);
  // const sortedList = payload && payload.sort((a, b) => {
  //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  
  //   // names must be equal
  //   return 0;
  // });

  // console.log('sortedList', sortedList)

  const dispatch = useDispatch();

  let currentCustomer = getCurrentCustomerFromLS()

  useEffect(() => {
    dispatch(coreFetchCustomers())
    .then((res) => {
      const customerFromLS = getCurrentCustomerFromLS()
      const customer = Object.keys(customerFromLS).length !== 0 ? customerFromLS : res.value[0]
      setCurrentCustomerToLS(customer)
      dispatch(coreSetCurrentCustomer(customer))
    })
    .catch((error) => {
      message.error(error.message || error);
    });
  }, []); //eslint-disable-line

  const onSelect = (id) => {
    const selectedCustomer = payload.find((customer) => customer.id === id )
    if (selectedCustomer) {
      dispatch(coreSetCurrentCustomer(selectedCustomer))
      setCurrentCustomerToLS(selectedCustomer)
    }    
  }

  return (
    <Select
      className={className}
      size="small"
      style={{ width: '200px', marginRight: '20px' }}
      placeholder="Demo"
      allowClear
      defaultValue={currentCustomer.name}
      onSelect={onSelect}
    >
    {customers && customers.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          { item.name }
        </Select.Option>
      ))}
    </Select>
  );
}

UserName.propTypes = {
  className: PropTypes.string,
};

UserName.defaultProps = {
  className: '',
};

export default UserName;
