import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Popconfirm } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

function CancelOrderButton({
  orderId,
  afterSuccess,
  className,
  filled,
  stateReducer,
  cancelOrder,
}) {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => stateReducer(state).cancelOrder.isLoading,
    shallowEqual
  );

  const handleClick = useCallback(() => {
    dispatch(cancelOrder(orderId))
      .then(() => {
        message.success('Order has been canceled!');
        afterSuccess();
      })
      .catch(() => {
        message.error("Can't cancel order!");
      });
  }, [cancelOrder, orderId, afterSuccess, dispatch]);

  return (
    <Popconfirm
      title={`Return ${filled} keys to stock from order ${orderId}?`}
      onConfirm={handleClick}
      okText="Yes"
      cancelText="No"
    >
      <Button
        danger
        className={className}
        loading={isLoading}
        size="small"
      >
        Cancel
      </Button>
    </Popconfirm>
  );
}

CancelOrderButton.propsTypes = {
  orderId: PropTypes.number.isRequired,
  afterSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
  stateReducer: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
};

CancelOrderButton.defaultProps = {
  className: '',
};

export default CancelOrderButton;
