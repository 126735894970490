import React from 'react';
import PropTypes from 'prop-types';
import cx from '../../../Utils/cx';

function getColor(amount) {
  if (amount < 0) {
    return 'color-red';
  }
  if (amount > 0) {
    return 'color-green';
  }

  return '';
}

function PrintAmount({
  amount,
  currency,
  className,
  colorize,
  withZero,
  classNameAmount,
}) {
  const classColorize = getColor(amount);
  if (amount || withZero) {
    return (
      <span className={className}>
        <small style={{ marginRight: 3 }}>{currency}</small>
        <b className={cx((colorize && classColorize) || '', classNameAmount)}>
          {amount.toFixed(2).toLocaleString()}
        </b>
      </span>
    );
  }

  return (
    <span className={className}>
      <i>none</i>
    </span>
  );
}

PrintAmount.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameAmount: PropTypes.string,

  colorize: PropTypes.bool,
  withZero: PropTypes.bool,
};

PrintAmount.defaultProps = {
  classNameAmount: '',
  className: '',
  colorize: false,
  withZero: false,
};

export default PrintAmount;
