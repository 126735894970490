import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import createStore from './Boots/createStore';
import TokenService from './Services/TokenService';
import { authSetIsAuthenticated } from './Reducers/auth';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './Boots/App';
import { BrowserRouter } from 'react-router-dom';

const store = createStore({});

if (TokenService.hasToken()) {
  store.dispatch(authSetIsAuthenticated(true));
}

TokenService.subscribe(function (isAuthenticated) {
  store.dispatch(authSetIsAuthenticated(isAuthenticated));
});

const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  root.render(<AppRouter />);
} catch (error) {
  console.log(`ERROR: ${error}`);
}

serviceWorker.unregister();
