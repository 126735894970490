import { Button, message, Popconfirm } from 'antd';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { StopOutlined } from '@ant-design/icons';

function StopOrderButton({
  className,
  orderId,
  afterSuccess = () => {},
  stateReducer,
  fetchOrderInfo,
  stopOrder,
  stopOrderReset,
}) {
  const dispatch = useDispatch();

  const handleStopOrder = useCallback(() => {
    dispatch(stopOrder(orderId))
      .then(() => {
        message.success('Order execution was successfully stopped');
        dispatch(fetchOrderInfo(orderId));
        afterSuccess();
      })
      .catch((e) => message.error(`Order stop error: ${e}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(stopOrderReset()), []);

  const isLoading = useSelector(
    (state) => stateReducer(state).stopOrder.isLoading,
    shallowEqual
  );

  return (
    <Popconfirm
      title="Do you really want to stop the execution of an order?"
      okText="Yes"
      cancelText="No"
      onConfirm={handleStopOrder}
    >
      <Button
        loading={isLoading}
        type="primary"
        className={className}
        danger
        size="small"
        icon={<StopOutlined />}
      >
        Stop Order
      </Button>
    </Popconfirm>
  );
}

StopOrderButton.propTypes = {
  className: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  stateReducer: PropTypes.func.isRequired,
  fetchOrderInfo: PropTypes.func.isRequired,
  stopOrder: PropTypes.func.isRequired,
  stopOrderReset: PropTypes.func.isRequired,
};

StopOrderButton.defaultProps = {
  className: '',
};

export default React.memo(StopOrderButton);
