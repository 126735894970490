import { Avatar, Button, Input, List, Spin, Switch, Tooltip, Typography, message } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { coreFetchCustomers, coreSetSelectedCustomers, fetchSelectedCustomers, setCreditLimit } from '../../Reducers/core'
import './styles.scss'
const { Title } = Typography;


function ManagerSettings() {

  const [enableChangeLimitId, setEnableChangeLimitId] = useState(null);
  const [newCreditLimit, setNewCreditLimit] = useState(0);

  const dispatch = useDispatch()

  const { payload, isLoading } = useSelector((state) => state.core.customers, shallowEqual)
  const selectedCustomersData = useSelector((state) => state.core.customersSettingsSelected, shallowEqual)
  const updateData = useSelector((state) => state.core.creditLimit, shallowEqual)

  const userId = selectedCustomersData?.payload && selectedCustomersData?.payload?.userId
  const selectedIsloading = selectedCustomersData?.isLoading
  const selectedCustomers = selectedCustomersData?.payload && selectedCustomersData?.payload?.selectedCustomers

  const updateDataIsLoading = updateData?.isLoading;

  const handleChangeUserToList = useCallback((e, id) => {
    let data
    
    if (selectedCustomers.length === 1 && e === false) {
      message.error(`Can't remove final customer, please select at least one!`)
    } else {

    if (selectedCustomers) {
      data = e ? [...selectedCustomers, id] : selectedCustomers.filter((customer) => customer != id);
    } else {
      data = [id]
    }

    const dataToSend = {userId, selectedCustomers: data};
    dispatch(coreSetSelectedCustomers(dataToSend))
    .then((res) => {
      message.success(`Successfully ${e ? "Added" : "Removed"} user!`)
    })
    .catch((e) => {
      message.error(`Failed to ${e ? "Add" : "Remove"} user! ${e}`)
    })
  }
  }, [selectedCustomers])

  const handleEnableChangingBalance = useCallback((e, item) => {
    if (!e) {
      setEnableChangeLimitId(null)
      setNewCreditLimit(null)
    } else {
      setEnableChangeLimitId(item.id);
      setNewCreditLimit(item.creditLimit)
    }
  })

  const handleSaveNewLimit = useCallback((id, status) => {

    var creditLimit = status === 'reset' ? 0 : +newCreditLimit

    dispatch(setCreditLimit({customerId: id, creditLimit }))
      .then((res) => {
        message.success(`Updated credit limit: ${creditLimit} €`);
      })
      .catch((e) => {
        message.error(`Failed to update credit limit: ${e}`);
      })
      .finally(() => {
        setEnableChangeLimitId(null);
        dispatch(coreFetchCustomers());
      })
  }, [newCreditLimit])

  useEffect(() => {
    dispatch(fetchSelectedCustomers())
  }, [dispatch]);

  return (
    <>
    <Title>Managers Settings</Title>
    {selectedIsloading ? 
        <Spin /> 
        :
        <List
        grid={{gutter: 16, column: 4}}
        itemLayout="horizontal"
        dataSource={payload || []}
        renderItem={(item, index) => {
          const isActiveUser = selectedCustomers?.includes(item.id)
          return (
          
          <List.Item>
            <List.Item.Meta
              // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
              title={<div className='user_avatar'><Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} /><h3>{item.name}</h3></div>}
              description={
              <div className='user_info'>
              <div className='user_info__balance'>Balance: <b>{item.balance} €</b></div>
              <div className='user_info__credit'>
              <Input 
                size='small'
                type='number' 
                value={enableChangeLimitId !== item.id ? item.creditLimit : newCreditLimit} 
                onChange={(e) => setNewCreditLimit(e.target.value)}
                addonAfter='€' 
                addonBefore='Credit'
                disabled={enableChangeLimitId !== item.id} 
                loading={isLoading}
              />
              {enableChangeLimitId === item.id &&
              <>
              <Button 
                size='small'
                onClick={() => handleSaveNewLimit(item.id, 'set')}
                isLoading={updateDataIsLoading}
                >
                  Save
                </Button>
                <Button 
                size='small'
                onClick={() => handleSaveNewLimit(item.id, 'reset')}
                >
                  Reset
                </Button>
                </>
                }
              <Tooltip title={`change limit ${item.creditLimit > 0 ? 'to zero' : ''}`}>
              <Switch
                size="small"
                checked={enableChangeLimitId === item.id}
                onChange={(e) => handleEnableChangingBalance(e, item)}
              />
              
              </Tooltip>
              </div>
              <div className='user_info__active'>
              {isActiveUser ? 'Delete from list: ' : 'Add user to list: ' }
              <Switch
                defaultChecked={isActiveUser}
                onChange={(e) => handleChangeUserToList(e, item.id)}
              />
              </div>
              </div>}
            />
          </List.Item>
        )
        }}
      />}
  </>
  )
}

export default ManagerSettings
