import React from 'react';
import RedirectToXBOXPage from '../HOC/RedirectToXBOXPage';
import XboxPrice from '../Pages/XboxPrice';
import XboxOrders from '../Pages/XboxOrders';
import ActivationsPage from '../Pages/Activations';
import BalancePage from '../Pages/Balance';
import PriceListSteam from '../Pages/PriceListSteam';
import BlackListPage from '../Pages/BlackList';
import RedirectPage from '../Pages/RedirectPage';
import RedirectUserToMainPage from '../HOC/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../HOC/RedirectGuestToSignInPage';
import BlizzardPrice from '../Pages/BlizzardPrice';
import BlizzardOrders from '../Pages/BlizzardOrders';
import { useRoutes } from 'react-router-dom';
import SettingsGeneral from '../Pages/SettingsGeneral';
import SettingsUser from '../Pages/SettingsUser';
import ManagerSettings from '../Pages/ManagerSettings';
import BlackListProducts from '../Pages/BlackListProducts';
import Page404 from '../Pages/Page404';

function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage />,
    },
    {
      path: '/redirect',
      exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [
        {
          path: '/balance',
          exact: true,
          element: <BalancePage />,
        },
        {
          path: '/steam/price-list',
          exact: true,
          element: <PriceListSteam />,
        },
        {
          path: '/',
          exact: true,
          element: <XboxPrice />,
        },
        {
          path: '/orders',
          exact: true,
          element: <XboxOrders />,
        },
        {
          path: '/blizzard/orders',
          exact: true,
          element: <BlizzardOrders />,
        },
        {
          path: '/blizzard/price',
          exact: true,
          element: <BlizzardPrice />,
        },
        {
          path: '/black-list',
          exact: true,
          element: <BlackListPage />,
        },
        {
          path: '/activations',
          exact: true,
          element: <ActivationsPage />,
        },
        {
          path: '/managers/settings',
          exact: true,
          element: <ManagerSettings />
        },
        {
          path: '/settings/general',
          exact: true,
          element: <SettingsGeneral />,
        },
        {
          path: '/settings/customer',
          exact: true,
          element: <SettingsUser />,
        },
        {
          path: '/blacklist',
          exact: true,
          element: <BlackListProducts />,
        },
        {
          path: '/',
          exact: true,
          element: <RedirectToXBOXPage />,
        },
        {
          path: '*',
          exact: true,
          element: <Page404 />,
        },
      ],
    },
  ]);
  return element;
}

export default Routes;
