import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { useDispatch } from 'react-redux';

function CheckKeys({ className, afterSuccess, orderId, checkCodes }) {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(checkCodes(orderId))
    .then(({ value }) => {
      const n = value.reduce((acc, v) => acc + v[1], 0);
      if (n) {
        afterSuccess();
      }
      message.success(`${n} Keys found from Engine`);
    })
    .catch((error) => {
      message.error(error.message || error);
    });
  }, [dispatch, orderId, afterSuccess]);

  return (
    <Button
      size="small"
      type="primary"
      ghost
      className={className}
      onClick={handleClick}
    >
      Check Keys
    </Button>
  );
}

CheckKeys.propTypes = {
  className: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  afterSuccess: PropTypes.func.isRequired,
  checkCodes: PropTypes.func.isRequired,
};

CheckKeys.defaultProps = {
  className: '',
};

export default CheckKeys;
