import React from 'react';
import { Tooltip } from 'antd';
import { getDiffDays, unixToMoment } from '../../Utils/date';
import PrintDate from '../PrintDate';
import Icon from '@ant-design/icons/lib/components/Icon';

function PrintUntil({ date, printDiffDays = false, withTime = false }) {
  if (!date) return <span>-</span>;

  if (date === 4102444800000) {
    return (
      <Tooltip title="Until is unset">
        <Icon type="question" />
      </Tooltip>
    );
  }

  if (printDiffDays) {
    const countDays = getDiffDays(unixToMoment(date));
    const days = countDays === 1 ? 'day' : 'days';

    return (
      <>
        <PrintDate date={date} withTime={withTime} /> ({countDays} {days})
      </>
    );
  }

  return <PrintDate date={date} withTime={withTime} />;
}

export default React.memo(PrintUntil);
