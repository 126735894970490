const USERNAME_SETTING_LS_KEY = '__cargo__user_settings__';

class UserSettingsService {
  setSettings(obj) {
    localStorage.setItem(USERNAME_SETTING_LS_KEY, JSON.stringify(obj));
  }

  getSettings() {
    return JSON.parse(localStorage.getItem(USERNAME_SETTING_LS_KEY)) || {};
  }

  getValue(name) {
    return this.getSettings()[name] || null;
  }

  setValue(name, value) {
    const settings = this.getSettings();
    settings[name] = value;
    this.setSettings(settings);
  }

  onSearch(name, onSearch, query) {
    const settings = this.getSettings();
    settings[name] = { ...(settings[name] || {}), query };
    this.setSettings(settings);

    return onSearch(query);
  }

  getSorterAndFiltersAndQueryAndPageSize(name) {
    const settings = this.getSettings()[name] || {};

    return {
      sorter: settings.sorter || {},
      filters: settings.filters || {},
      query: settings.query || '',
      pageSize: settings.pageSize || 50,
    };
  }

  setFilters(name, setFilters, filters) {
    const settings = this.getSettings();
    settings[name] = { ...(settings[name] || {}), filters };
    this.setSettings(settings);

    setFilters(filters);
  }

  onChangeSettings(name, onChange, a, b, c) {
    const settings = this.getSettings();

    settings[name] = {
      ...(settings[name] || {}),
      pageSize: a.pageSize || 50,
      sorter:
        (c &&
          c.field &&
          c.order && {
          field: c.field,
          order: c.order,
        }) ||
        {},
      filters: b || {},
    };
    this.setSettings(settings);

    return onChange(a, b, c);
  }
}

export default new UserSettingsService();
