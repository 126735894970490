import dayjs from 'dayjs';
import moment from 'moment';

export const DEFAULT_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm';

moment.locale('en', {
  week: {
    dow: 1,
  },
});

export function utcToDefaultFormat(date) {
  return (date && moment.utc(date).format(DEFAULT_FORMAT)) || null;
}

export function getCurrentUtc() {
  return dayjs();
}

export function utcToDefaultFormatWithTime(date) {
  return (date && moment.utc(date).format(DEFAULT_FORMAT_WITH_TIME)) || null;
}

export function getDefaultRange() {
  return [
    moment()
      .subtract(1, 'month')
      .startOf('day')
      .valueOf(),
    moment()
      .endOf('day')
      .valueOf(),
  ];
}

export function unixToMoment(unix) {
  return (+unix && dayjs(+unix)) || null;
  // return (+unix && moment(+unix)) || null;
}

export function momentToUnix(v) {
  return (v && v.valueOf()) || null;
}

export function momentToUnixRange(arr) {
  if (!arr) {
    return [null, null];
  }

  const [from, to] = arr;

  return [
    (from && from.startOf('day').valueOf()) || null,
    (to && to.endOf('day').valueOf()) || null,
  ];
}

export function getCurrentUnix() {
  return moment().valueOf();
}

export function getDiffDays(date) {
  return date && date.diff(moment(), 'days');
}

// Ranges

export function getLastWeekRange() {
  return [
    moment
      .utc()
      .subtract(1, 'week')
      .startOf('week'),
    moment
      .utc()
      .subtract(1, 'week')
      .endOf('week'),
  ];
}

export function getLastMonthRange() {
  return [
    moment
      .utc()
      .subtract(1, 'month')
      .startOf('month'),
    moment
      .utc()
      .subtract(1, 'month')
      .endOf('month'),
  ];
}

export function dateRangePeriods() {
  return {
    'Last Week': getLastWeekRange(),
    'Last Month': getLastMonthRange,
    Today: [moment.utc().startOf('day'), moment.utc().endOf('day')],
    'This Week': [moment.utc().startOf('week'), moment.utc().endOf('week')],
    'This Month': [moment.utc().startOf('month'), moment.utc().endOf('month')],
  };
}
