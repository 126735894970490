import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Col, Drawer, Input, message, Row, Table } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  priceListCreate,
  priceListSearchCreate,
  priceListSearchCreateReset,
} from '../../../Reducers/priceList';
import PrintPrice from '../../../Components/PrintPrice';
import RegionSelector from '../../../Components/RegionSelector';
import useUpdateEffect from '../../../Utils/useUpdateEffect';
import './styles.scss';
import PrintUntil from '../../../Components/PrintUntil';
import AddProductTitle from './AddProductTitle';

const b = b_.lock('AddProduct');

const { Search } = Input;
const { Column } = Table;

function AddProduct({ isOpen, close }) {
  const [selected, setSelected] = useState([]);
  const [regions, setRegions] = useState(['ar', 'glo', 'na', 'ru', 'eu']);
  const dispatch = useDispatch();

  const { list, loadingSearch } = useSelector(
    (state) => ({
      list: state.priceList.searchCreate.payload,
      loadingSearch: state.priceList.searchCreate.isLoading,
    }),
    shallowEqual
  );

  useUpdateEffect(() => {
    setSelected([]);
  }, [list]);

  const onSearch = useCallback(
    (value) => {
      if (!value.trim()) {
        message.error('Name is required');
        return;
      }
      const preparedRegion = regions.includes('ar') ? ['ar'] : [];

      if (!preparedRegion.length) {
        message.error('Please select regions!');
        return;
      }

      dispatch(priceListSearchCreate(value.trim(), preparedRegion)).catch(
        (e) => {
          message.error(e);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regions]
  );

  const onClose = useCallback(() => {
    dispatch(priceListSearchCreateReset());
    close();
  }, [close]); //eslint-disable-line

  const onCreate = useCallback(() => {
    dispatch(priceListCreate(selected))
      .then(() => {
        onClose();
        message.success('Products has been added!');
      })
      .catch((error) => {
        message.error(error);
      });
  }, [selected]); //eslint-disable-line

  return (
    <Drawer
      title={
        <AddProductTitle
          disabled={!selected.length}
          onCreate={onCreate}
          onClose={onClose}
        />
      }
      placement="top"
      closable={false}
      onClose={onClose}
      visible={isOpen}
      height="650px"
      destroyOnClose
    >
      {isOpen && (
        <>
          <Row gutter={16} className="mb">
            <Col span={24}>
              <RegionSelector selected={regions} onChange={setRegions} />
            </Col>
          </Row>
          <Row gutter={16} className="mb">
            <Col span={24}>
              <Search
                placeholder="Name"
                className="mb"
                size="large"
                onSearch={loadingSearch ? null : onSearch}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Table
                bordered
                className="ant-table-white-bg"
                dataSource={list}
                loading={loadingSearch}
                rowKey="id"
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                pagination={false}
                scroll={{ y: 350 }}
              >
                <Column title="Sub ID" dataIndex="sub_id" width={100} />
                <Column title="Name" dataIndex="game" />
                <Column title="Region" dataIndex="region" width={200} />
                <Column
                  title="Price, €"
                  dataIndex="price"
                  className="price-column"
                  width={150}
                  render={(text, rec) => (
                    <div className={b('price', { withSale: !!rec.sale_price })}>
                      <PrintPrice price={text} />
                    </div>
                  )}
                />
                <Column
                  title="Sale Price"
                  dataIndex="sale_price"
                  className="price-column"
                  align="left"
                  width={150}
                  render={(text) => <PrintPrice price={text} />}
                />
                <Column
                  title="Until"
                  dataIndex="sale_until"
                  width={200}
                  render={(date) => <PrintUntil date={date} />}
                />
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Drawer>
  );
}

AddProduct.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default React.memo(AddProduct, (prevProps, nexProps) => {
  return prevProps.isOpen === nexProps.isOpen;
});
