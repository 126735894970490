import React, { useCallback } from 'react';
import { Popconfirm, Tooltip, message } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { blackListRemoveGiftcode } from '../../../Reducers/blackList';
import Icon from '@ant-design/icons/lib/components/Icon';

function RemoveFromBlacklistButton({ giftcode }) {
  const dispatch = useDispatch();

  const onConfirm = useCallback(() => {
    dispatch(blackListRemoveGiftcode(giftcode)).catch((error) =>
      message.error(error)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftcode]);

  return (
    <Popconfirm
      title="Are you sure you want to remove this giftcode from your blacklist?"
      okText="Yes"
      cancelText="No"
      onConfirm={onConfirm}
    >
      <Tooltip placement="bottom" title="Remove giftcode from blacklist">
        <Icon type="delete" />
      </Tooltip>
    </Popconfirm>
  );
}

RemoveFromBlacklistButton.propTypes = {
  giftcode: PropTypes.string.isRequired,
};

export default React.memo(RemoveFromBlacklistButton);
