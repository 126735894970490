import ActivationsService from '../Services/ActivationsService';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const activationsFetchList = createAction('activations/fetch-list', (params, codes) => ({
  payload: ActivationsService.fetchList(params, codes)
}))

const initState = {
  list: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(activationsFetchList, 'list')
})
