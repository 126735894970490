import React from 'react';
import { Button, InputNumber, message, Slider } from 'antd';
import b_ from 'b_';
import PropTypes from 'prop-types';
import useDebounce from '../../Utils/useDebounce';
import useBoolean from '../../Utils/useBoolean';
import './styles.scss';

const b = b_.lock('RangeInput');

const RangeInput = ({ label, min, max, value, onChange, className }) => {
  const { value: inputMode, toggleValue: toggleInputMode } = useBoolean(true);

  const { value: isFocused, setFalse: onBlur, setTrue: onFocus } = useBoolean(
    false,
  );

  useDebounce(
    (value = [], onChange) => {
      const [minValue, maxValue] = value;
      if (typeof minValue !== 'number' || typeof maxValue !== 'number') return;

      if (minValue > maxValue && minValue !== maxValue) {
        if (isFocused) {
          onChange([minValue, minValue]);
          message.error(
            'Invalid max value! Changing max value in the filter ...',
          );
        } else {
          onChange([maxValue, maxValue]);
          message.error(
            'Invalid min value! Changing min value in the filter ...',
          );
        }
      }
    },
    500,
    value,
    onChange,
  );

  return (
    <div className={`${b()} ${className}`}>
      <div className={`${b('label')} mb-small`}>
        <b>{label}</b>
        <Button
          type="link"
          // size="small"
          onClick={toggleInputMode}
          className={b('viewMode')}
        >
          <small>view mode</small>
        </Button>
      </div>
      <div className={b('container')}>
        {inputMode ? (
          <>
            <InputNumber
              min={min}
              max={max}
              // size="small"
              onFocus={onFocus}
              onBlur={onBlur}
              className={b('input')}
              value={(value && value[0]) || min}
              onChange={(e) => onChange([e, value[1]])}
            />
            <span className="mr-small ml-small">-</span>
            <InputNumber
              max={max}
              min={min}
              // size="small"
              className={b('input')}
              value={(value && value[1]) || max}
              onChange={(e) => onChange([value[0], e])}
            />
          </>
        ) : (
          <>
            <span className="mr-small">{min}</span>
            <Slider
              range
              className={b('range')}
              value={value}
              onChange={onChange}
              min={min}
              max={max}
            />
            <span className="ml-small">{max}</span>
          </>
        )}
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

RangeInput.defaultProps = {
  className: '',
};

export default React.memo(RangeInput);
