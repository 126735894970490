import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import Title from 'antd/lib/typography/Title';
import RangeInput from '../../../Components/RangeInput';
import RegionSelect from '../../../Components/RegionSelect';
import './styles.scss';
import { momentToUnixRange, unixToMoment } from '../../../Utils/date';
import { SearchOutlined, StopOutlined } from '@ant-design/icons';
import UserSettingsService from '../../../Services/UserSettingsService';

const b = b_.lock('PriceFilters');

const emptyFilters = {
  query: '',
  price: [0, 100],
  sale_pct: [0, 100],
  sale_until: [null, null],
};

function filtersToSetFilters(filters, query) {

  return {
    query,
    region:
      filters?.region && filters.region.length ? filters.region[0] : undefined,
    sale_pct: [filters?.sale_pct_from || 0, filters?.sale_pct_to || 100],
    price: [filters?.price_from || 0, filters?.price_to || 3000],
    sale_until: [filters?.sale_until_from, filters?.sale_until_to],
    tag: filters?.tag,
  };
}

function tFiltersToFiltets(tFilters) {
  return {
    filters: {
      region: tFilters.region ? [tFilters.region] : undefined,
      tag: tFilters.tag,
      sale_pct_from:
        tFilters.sale_pct[0] === 0 ? undefined : tFilters.sale_pct[0],
      sale_pct_to:
        tFilters.sale_pct[1] === 100 ? undefined : tFilters.sale_pct[1],
      price_from: tFilters.price[0] === 0 ? undefined : tFilters.price[0],
      price_to: tFilters.price[1] === 3000 ? undefined : tFilters.price[1],
      sale_until_from:
        tFilters.sale_until.length && tFilters.sale_until[0]
          ? tFilters.sale_until[0]
          : undefined,
      sale_until_to:
        tFilters.sale_until.length && tFilters.sale_until[1]
          ? tFilters.sale_until[1]
          : undefined,
    },
    query: tFilters.query,
  };
}

function PriceFilters({
  className,
  setFiltersAndQuery,
  stateReducer,
  fetchRegions,
  customer,
  afterUpdate
}) {

  const { filters, query } = UserSettingsService.getSorterAndFiltersAndQueryAndPageSize('XBOXPrice')
  const [tFilters, setTfilters] = useState(filtersToSetFilters(filters, query));

  const handleChange =
    (newValue, field) => {
      setTfilters({ ...tFilters, [field]: newValue });
    }

  const handleFilter = useCallback(() => {
    const { query, filters } = tFiltersToFiltets(tFilters);
    setFiltersAndQuery(filters, query);
    UserSettingsService.setFilters('XBOXPrice', setFiltersAndQuery, filters);
  }, [setFiltersAndQuery, tFilters]);

  const handleReset = useCallback(() => {
    const { query, filters } = tFiltersToFiltets(emptyFilters);
    setFiltersAndQuery(filters, query);
    UserSettingsService.setFilters('XBOXPrice', setFiltersAndQuery, filters);
  }, [setFiltersAndQuery]);

  const onEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleFilter();
      }
    },
    [handleFilter]
  );

  useEffect(() => {
    // const filters = UserSettingsService.getSorterAndFiltersAndQueryAndPageSize('XBOXPrice')
    handleFilter();
    // setTfilters(filtersToSetFilters(filters, query));
    // UserSettingsService.setFilters('XBOXPrice', setFiltersAndQuery, filters);
  }, [customer, tFilters]);

  return (
    <div className={className} onKeyDown={onEnter}>
      <Title className={b('title')} level={2}>
        Filters
        <div>
          <Button
            className="mr-small"
            icon={<StopOutlined />}
            size="large"
            ghost
            type="primary"
            onClick={handleReset}
          >
            Reset filters
          </Button>
          {/* <Button
            icon={<SearchOutlined />}
            size="large"
            type="primary"
            onClick={handleFilter}
          >
            Apply filters
          </Button> */}
        </div>
      </Title>
      <Row gutter={16} className="mb-small">
        <Col span={4}>
          <RangeInput
            min={0}
            max={100}
            value={tFilters.sale_pct}
            onChange={(v) => handleChange(v, 'sale_pct')}
            label="Sale, %"
          />
        </Col>
        <Col span={4}>
          <RangeInput
            min={0}
            max={5000}
            value={tFilters.price}
            onChange={(v) => handleChange(v, 'price')}
            label="Price, €"
          />
        </Col>
        <Col span={4}>
          <div className={`${b('label')} mb-small`}>
            <b>Region</b>
          </div>
          <div className={b('filterContainer')}>
            <RegionSelect
              onChange={(v) => handleChange(v, 'region')}
              value={tFilters.region}
              stateReducer={stateReducer}
              fetchRegions={fetchRegions}
            />
          </div>
        </Col>
        <Col span={4}>
          <div className={`${b('label')} mb-small`}>
            <b>Tags</b>
          </div>
          <div className={b('filterContainer')}>
            <Select
              value={tFilters.tag}
              onChange={(v) => handleChange(v, 'tag')}
              mode="tags"
              // size="small"
              style={{ width: '100%' }}
            >
              <Select.Option value="favorites">Favorites</Select.Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className={`${b('label')} mb-small`}>
            <b>Discount date</b>
          </div>
          <div className={b('filterContainer')}>
            <DatePicker.RangePicker
              // size="small"
              onChange={(v) => handleChange(momentToUnixRange(v), 'sale_until')}
              value={
                tFilters.sale_until && tFilters.sale_until.length
                  ? tFilters.sale_until.map((v) => unixToMoment(+v))
                  : [null, null]
              }
              className="block"
              style={{ width: '100%' }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PriceFilters;
