import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import SignIn from '../Pages/SingIn';

function RedirectUserToMainPage() {
  let location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  if (location.pathname === '/sign-out') {
    location.pathname = '/';
  }
  return !isAuthenticated ? <SignIn /> : <Navigate to={location.pathname || '/'} />
}

export default RedirectUserToMainPage;
