import { Typography } from 'antd'
import React from 'react'
import './styles.scss'

const { Title } = Typography

const Page404 = () => {
  return (
    <div className='notfound_wrapper'>
    <Title>404 Not Found</Title>
    <span>Sorry, the page you are looking for does not exist</span>
    </div>
  )
}

export default Page404
