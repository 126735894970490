import React, { useEffect, useState } from 'react';
import { Avatar, Layout, Menu, message } from 'antd';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CurrentBalance from '../CurrentBalace';
import './styles.scss';
import Logout from '../Logout';
import { coreFetchCustomers, coreFetchSelectedCustomers, coreSetCurrentCustomer, fetchAccountData} from '../../Reducers/core';
import { getCurrentCustomerFromLS, setCurrentCustomerToLS} from '../../Services/CoreService'
import UserName from '../PrintUserName';
import IncreaseBalance from '../Balance/Increase';
import DecreaseBalance from '../Balance/Decrease';
import { UserOutlined } from '@ant-design/icons';
// import { withRouter } from 'react-router';


const AntHeader = Layout.Header;

function getLocation(pathname, exact = false) {
  if (exact) {
    return pathname;
  }

  if (pathname && pathname === '/') {
    return '/';
  }
  const arr = pathname.split('/');

  if (arr && arr.length >= 2 && arr[1] !== '') {
    return `/${arr[1]}`;
  }

  return pathname;
}

function Header({ location, isManager }) {
  const dispatch = useDispatch();

    const payload = useSelector(
    (state) => state.core.customer,
    shallowEqual
  );

  const customers = useSelector(
    (state) => state.core.customersSelected,
    shallowEqual
  );

  const nonManagerCustomer = useSelector(
    (state) => state.core.account,
    shallowEqual
  );

  // Логика перезаписи кастомера после изменений на бэкенде - работает при перезагрузке страницы

  const customersList = customers && customers?.payload

  const nonManagerCustomerBalance = nonManagerCustomer.payload && nonManagerCustomer.payload.balance
  const nonManagerCustomerCredit = nonManagerCustomer.payload && nonManagerCustomer.payload.creditLimit
  const nonManagerCustomerName = nonManagerCustomer.payload && nonManagerCustomer.payload.name

  const currentCustomer = getCurrentCustomerFromLS()

  const [customersListState, setCustomersListState] = useState([]);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    
    if (!customersList) {
      setIsLoading(true)
    } else {
      setCustomersListState(customersList)
      setIsLoading(false)
    }

  }, [customersList]);

  useEffect(() => {
    if (isLoading === false) {
      const findCurrentCustomer = customersListState.find((customer) => customer.id === currentCustomer.id)

      if (!findCurrentCustomer) {
        localStorage.setItem('__current_customer__', JSON.stringify(customersListState[0]));
      } 
      if (currentCustomer && findCurrentCustomer) {
        const updatedCustomer = { ...currentCustomer };

        for (const key in updatedCustomer) {
          if (updatedCustomer[key] !== findCurrentCustomer[key] || !findCurrentCustomer) {
            updatedCustomer[key] = findCurrentCustomer[key];
          }
        }

        // dispatch(coreSetCurrentCustomer(updatedCustomer))
        localStorage.setItem('__current_customer__', JSON.stringify(updatedCustomer));
      }
    }
  }, [isLoading, customersListState]);

  // Конец логики автоматизированной перезаписи кастомера
  
  console.log(isManager)
  useEffect(() => {
    if (isManager !== null) {
      if (isManager) {
      dispatch(coreFetchSelectedCustomers())
      .then((res) => {
        // dispatch(coreSetCurrentCustomer(res.value[0]))
        // setCurrentCustomerToLS(res.value[0])
      })
      .catch((error) => {
        message.error(error.message || error);
      });
    } else {
      console.log('FETCHED')
      dispatch(fetchAccountData())
    }
  }
    
  }, [isManager]); //eslint-disable-line

  

  useEffect(() => {
    dispatch(coreSetCurrentCustomer(currentCustomer))
  }, [])
  
  console.log('CURCUSTOMER', payload.creditLimit)

  return (
    <AntHeader className="Header" >
      <Menu
        theme="dark"
        defaultSelectedKeys={[getLocation(location.pathname, true)]}
        selectedKeys={[getLocation(location.pathname, true)]}
        mode="horizontal"
        style={{ lineHeight: "64px", display: "flex", marginRight: "300px" }}
      >
        <Menu.Item key="/">
          <Link to="/">XBOX Price</Link>
        </Menu.Item>
        <Menu.Item key="/blizzard/price">
          <Link to="/blizzard/price">Blizzard Price</Link>
        </Menu.Item>
        <Menu.Item key="/orders">
          <Link to="/orders">Orders</Link>
        </Menu.Item>
        {isManager && <><Menu.Item key="/settings/general">
          <Link to="/settings/general">General Settings</Link>
        </Menu.Item>
        <Menu.Item key="/settings/customer">
          <Link to="/settings/customer">Customer Settings</Link>
        </Menu.Item>
        <Menu.Item key="/managers/settings">
          <Link to="/managers/settings">Manager Settings</Link>
        </Menu.Item>
        </>
        }
        <Menu.Item key="/balance">
          <Link to="/balance">
            Balance: <CurrentBalance balance={isManager ? payload.balance : nonManagerCustomerBalance}/> / Credit: <CurrentBalance balance={isManager ? payload.creditLimit > 0 ? "-" + payload.creditLimit : payload.creditLimit : nonManagerCustomerCredit > 0 ? "-" + nonManagerCustomerCredit : nonManagerCustomerCredit}/>
            {/* / Credit: <span>{payload.creditLimit > 0 ? "-" + payload.creditLimit : payload.creditLimit} €</span> */}
          </Link>
        </Menu.Item>
        {isManager && 
        <>
        <Menu.Item key="/balance/increase">
            <IncreaseBalance id={payload.id} />
        </Menu.Item>
        <Menu.Item key="/balance/decrease">
            <DecreaseBalance id={payload.id} />
        </Menu.Item>
        </>}
      </Menu>

      <div className="Header__rightBlock">
      <Avatar style={{ backgroundColor: 'rgb(131, 73, 246)' }} icon={<UserOutlined />} />
      {isManager ? <UserName className=" Header__rightBlock__item" customers={customersList}/> : <span style={{color: 'white'}}>{nonManagerCustomerName}</span>}
      
        <Logout />
      </div>
    </AntHeader>
  );
}

export default React.memo(Header);
