import SettingsService from '../Services/SettingsService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

// COMMON SETTINGS
export const settingsConfigFetch = createAction('settings/config-fetch', () => ({
  payload: SettingsService.fetchSettings()
}))

export const settingsFetchReset = createAction('settings/settings-fetch_RESET')

export const settingsSchemaFetch = createAction('settings/schema-fetch', () => ({
  payload: SettingsService.fetchSettingsSchema()
}))

export const settingsUiSchemaFetch = createAction('settings/ui-schema-fetch', () => ({
  payload: SettingsService.fetchSettingsUISchema()
}))

export const settingsUpdate = createAction('settings/update', (data) => ({
  payload: SettingsService.updateSettings(data)
}))

export const settingsUpdateReset = createAction('settings/settings-update_RESET')

//USER SETTINGS
export const userSettingsConfigFetch = createAction('settings/user-config-fetch', () => ({
  payload: SettingsService.fetchUserSettings()
}))

export const userSettingsFetchReset = createAction('settings/user-settings-fetch_RESET')

export const userSettingsSchemaFetch = createAction('settings/user-schema-fetch', (id) => ({
  payload: SettingsService.fetchUserSettingsSchema(id)
}))

export const userSettingsUiSchemaFetch = createAction('settings/user-ui-schema-fetch', () => ({
  payload: SettingsService.fetchUserSettingsUISchema()
}))

export const userSettingsUpdate = createAction('settings/user-update', (data) => ({
  payload: SettingsService.updateUserSettings(data)
}))

export const userSettingsUpdateReset = createAction('settings/user-settings-update_RESET')

export const copySettingsToCustomers = createAction('settings/user-settings-copy', (data) => ({
  payload: SettingsService.copySettingsToOtherUsers(data)
}))

const initState = {
  configs: getDefaultState(),
  schema: getDefaultState(),
  uischema: getDefaultState(),
  updateSettings: getDefaultState(),

  userConfigs: getDefaultState(),
  userSchema: getDefaultState(),
  userUischema: getDefaultState(),
  userUpdateSettings: getDefaultState(),

  userSettingsCopy: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(settingsConfigFetch, 'configs'),
  ...getDefaultHandler(settingsSchemaFetch, 'schema'),
  ...getDefaultHandler(settingsUiSchemaFetch, 'uischema'),
  ...getDefaultHandler(settingsUpdate, 'updateSettings'),
  ...getDefaultHandler(userSettingsConfigFetch, 'userConfigs'),
  ...getDefaultHandler(userSettingsSchemaFetch, 'userSchema'),
  ...getDefaultHandler(userSettingsUiSchemaFetch, 'userUischema'),
  ...getDefaultHandler(userSettingsUpdate, 'userUpdateSettings'),
  ...getDefaultHandler(copySettingsToCustomers, 'userSettingsCopy')
})
