import { Button, DatePicker, Form, Input, message } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { customersChangeBalance } from '../../Reducers/balance';
import { getCurrentUtc, momentToUnix } from '../../Utils/date';
import Icon from '@ant-design/icons/lib/components/Icon';

function ChangeBalanceForm({ type, id }) {

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data) => {
      // e.preventDefault();
              dispatch(
                customersChangeBalance({
                  amount: type === 'increase' ? Number(form.getFieldValue("amount")) : Number(form.getFieldValue("amount")) * -1,
                  customerId: id,
                  day: momentToUnix(form.getFieldValue("date")),
                  notes: form.getFieldValue("description"),
                  type: 1,
                }),
              )
            .then(() => message.success(`Success ${type} balance.`))
            .catch((e) => message.error(`Error ${type} balance: ${e}`))
     
      form.resetFields();
    },
    // eslint-disable-next-line
    [form],
  );

  const isLoading = useSelector((state) => state.balance.changeBalance.isLoading, shallowEqual);

  const amountValidator = useCallback((rule, value, callback) => {
    if (value < 1 || Number.isNaN(value)) {
      callback('Please enter a number greater than 0.');
      return;
    }
    callback();
  }, []);
  
  return (
    <Form
        aria-orientation="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{ date: getCurrentUtc() }}
      >
      <Form.Item
        name='amount'
        rules={[
          {
            required: true,
            validator: amountValidator
          },
      ]}
      >
        <Input addonBefore={<Icon type={type === 'increase' ? 'plus' : 'minus'} />} addonAfter="€" size="small" />
      </Form.Item>

      <Form.Item
        name='date'
        rules={[
          {
            required: true,
            message: `Please select time.`,
          },
      ]}
      >
        <DatePicker style={{ width: '100%' }} showTime size="small" placeholder="Select Time" />
      </Form.Item>

      <Form.Item
      name='description'
      rules={[
        {
          required: true,
          message: `Please enter a description.`,
        },
    ]}
      >
        <Input addonBefore={<Icon type="book" />} size="small" />
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" type="primary" size="small" className="fr" loading={isLoading}>
          {type === 'increase' ? 'Increase' : 'Decrease'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ChangeBalanceForm;
