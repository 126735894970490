import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

function RedirectToXBOXPage() {
  let location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated, shallowEqual);

  if (location.pathname === '/sign-out') {
    location.pathname = '/';
  }
  return !isAuthenticated ? <Navigate to={`/sign-in`} /> : <Navigate to={location.pathname  || '/'} />

}

export default RedirectToXBOXPage;
