import React from 'react';
import {
  utcToDefaultFormat,
  utcToDefaultFormatWithTime,
} from '../../Utils/date';

function PrintDate({ date, withTime = false }) {
  return (
    <span>
      {withTime ? utcToDefaultFormatWithTime(date) : utcToDefaultFormat(date)}
    </span>
  );
}

export default PrintDate;
