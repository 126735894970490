import React from 'react';
import { Divider } from 'antd';
import './styles.scss';
import Icon from '@ant-design/icons/lib/components/Icon';

function DividerSelectedGames() {
  return (
    <Divider className="DividerSelectedGames">
      <Icon type="up" />
      &nbsp;&nbsp;
      <Icon type="up" />
      &nbsp;&nbsp;
      <Icon type="up" />
      &nbsp;&nbsp;Selected Games&nbsp;&nbsp;
      <Icon type="up" />
      &nbsp;&nbsp;
      <Icon type="up" />
      &nbsp;&nbsp;
      <Icon type="up" />
    </Divider>
  );
}

export default DividerSelectedGames;
