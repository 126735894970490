import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { orderFormFetch, orderPriceChange } from '../../Reducers/xbox';
import { Alert, Button, Checkbox, Col, Descriptions, Drawer, Input, message, Row, Typography } from 'antd';
import useBoolean from '../../Utils/useBoolean';
import { AppstoreTwoTone, EditOutlined } from '@ant-design/icons';
import PrintStatusOrder from '../../Components/Xbox/PrintStatusOrder';
import { useCallback } from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';

const platforms = {
  2: "Xbox",
  9: "Blizzard" 
}

const PrintOrderForm = ({ status, orderId, afterSuccess }) => {

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [isEdit, setIsEdit] = useState(true);
  const [isEditQty, setIsEditQty] = useState(true);
  const [qty, setQty] = useState(null);
  const [formData, setFormData] = useState({})
  const [price, setPrice] = useState(null);
  
 
  const {payload, isLoading} = useSelector((state) => state.xbox.fetchOrderForm, shallowEqual)
  const dispatch = useDispatch()

  const handleOpenForm = useCallback(() => {
    open()
    dispatch(orderFormFetch(orderId))
    .then((res) => {
      // console.log(res)
    })
    .catch((error) => {
      error.errors.map((err) => {
        message.error(`Failed to load order data: ${err.message}`)
      })
      console.log(error)
      close()
    })
  }, [dispatch])

  
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (payload) {
      setFormData(payload);
      setPrice(payload?.products?.[0]?.price)
      setQty(payload?.products?.[0]?.qty)
      if (payload.errors) {
        setErrors(payload.errors)
      }
    }
  }, [payload]);

  const handlePriceChange = (e) => {
    setPrice(parseFloat(e.target.value));
  };

  const handleQtyChange = (e) => {
    setQty(parseFloat(e.target.value));
  };

  const handleEditClick = () => {
    setIsEdit(false)
  };

  const handleEditQtyClick = () => {
    setIsEditQty(false)
  };

  const handleSaveClick = () => {
    const payload = [
      status === 9 ? {
        pltId: formData.products[0].pltId,
        productId: formData.products[0].productId,
        region: formData.products[0].region,
        maxPrice: price,
        qty
      } 
      :
      {
        pltId: formData.products[0].pltId,
        productId: formData.products[0].productId,
        region: formData.products[0].region,
        maxPrice: price
      }
    ];

    dispatch(orderPriceChange(payload, orderId))
    .then((res) => {
      message.success("Succefully updated order price")
      close();
      afterSuccess();
    })
    .catch((error) => {
      message.error(`Failed to save price: ${error}`)
    })
  };

  const renderInputs = () => {
    const { orderId, status, products, immediate, buyFromStock } = formData;

    if (orderId && status && products && Array.isArray(products)) {
      return (
        <>
          {products.map((product, index) => (
            <div key={index}>
            <Descriptions size="middle" title={`Order ID: ${orderId}`} bordered style={{marginBottom: '12px'}} loading={isLoading}>
            {product.pltId &&<Descriptions.Item label="Platform">{platforms[product.pltId] || product.pltId}</Descriptions.Item>}
            {product.productId && <Descriptions.Item label="Product ID">{product.productId}</Descriptions.Item>}
            {product.region && <Descriptions.Item label="Region">{product.region}</Descriptions.Item>}
            {product.qty && status === 9 ?
              <Descriptions.Item label="Qty">
                <Input
                  addonBefore={<EditOutlined onClick={handleEditQtyClick} />}
                  disabled={isEditQty} 
                  type="number"
                  value={qty}
                  onChange={handleQtyChange}
                  />
              </Descriptions.Item>
              :
              <Descriptions.Item label="Qty">
                {product.qty}
              </Descriptions.Item>
              }
            <Descriptions.Item label="Immediate"><Checkbox disabled checked={immediate} /></Descriptions.Item>
            <Descriptions.Item label="Buy from Stock"><Checkbox disabled checked={buyFromStock} /></Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
            <PrintStatusOrder status={status} />
            </Descriptions.Item>
            </Descriptions>
            <h3>Price:</h3>
            <Input
            style={{marginBottom: '12px'}}
            addonBefore={<EditOutlined onClick={handleEditClick} style={{color: 'black'}} />}
            disabled={isEdit} type="number"
            value={price}
            onChange={handlePriceChange}
            />
            <Button type="primary" onClick={handleSaveClick}>Save</Button>
            </div>
          ))}
        </>
      );
    }
    return <div class="loading-icon"></div>;
  };

  return (
    <>
    <a onClick={handleOpenForm}>{orderId}</a>
    <Drawer
    visible={isOpen}
    title="Change order"
    closable
    onClose={close}
    width={800}
    placement="right"
    >
      {errors ? (
        errors.map((errorObject, index) => <Alert style={{marginBottom: '12px'}} message={errorObject.message} type="error" showIcon />)
      ): (
        <div>{renderInputs()}</div>
      )}
    </Drawer>
    </>
  )
}

PrintOrderForm.propTypes = {
  orderId: PropTypes.number,
};

PrintOrderForm.defaultProps = {
  orderId: null,
};

export default PrintOrderForm
