import { combineReducers } from 'redux';
import auth from './auth';
import priceList from './priceList';
import blacklist from './blackList';
import balance from './balance';
import core from './core';
import activations from './activations';
import xbox from './xbox';
import blizzard from './blizzard';
import settings from './settings';

export default combineReducers({
  auth,
  priceList,
  balance,
  core,
  activations,
  blacklist,
  xbox,
  blizzard,
  settings,
});
