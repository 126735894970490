import { Col, DatePicker, Row, Button, Switch, Radio, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  dateRangePeriods,
  DEFAULT_FORMAT_WITH_TIME,
  momentToUnixRange,
  unixToMoment,
} from '../../../Utils/date';
import { ReloadOutlined } from '@ant-design/icons';
import UserSettingsService from '../../../Services/UserSettingsService';
import StatusSelect from '../../../Components/StatusSelect';

const { RangePicker } = DatePicker;
const { Search } = Input;

const emptyFilters = {
  query: '',
  status: [],
  date: [null, null],
  ext: ''
};

function filtersToSetFilters(filters, query) {

  return {
    query,
    status:
      filters?.status && filters.status.length ? filters.status[0] : undefined,
    date: [filters?.from, filters?.to],
    ext: filters?.ext
  };
}

function tFiltersToFilters(tFilters) {
  return {
    filters: {
      status: tFilters.status ? [tFilters.status] : undefined,
      from:
        tFilters.date.length && tFilters.date[0]
          ? tFilters.date[0]
          : undefined,
      to:
        tFilters.date.length && tFilters.date[1]
          ? tFilters.date[1]
          : undefined,
      ext: tFilters?.ext ? tFilters?.ext : undefined
    },
    query: tFilters.query,
  };
}

function Filters({ className, value, setFilters, fetchList, isLoading, changeActive, activeList, setFiltersAndQuery, isManager }) {

  const { filters, query } = UserSettingsService.getSorterAndFiltersAndQueryAndPageSize('XBOXOrder')
  const [tFilters, setTfilters] = useState(filtersToSetFilters(filters, query));


  const defaultRange = momentToUnixRange(dateRangePeriods()['This Week'])

  // const handleChange = useCallback(
  //   (dates) => {
  //     const range = dates.length
  //       ? momentToUnixRange(dates)
  //       : momentToUnixRange(dateRangePeriods()['This Week']);
  //     setFilters({
  //       from: range[0],
  //       to: range[1],
  //     });
  //   },
  //   [setFilters]
  // );

  const handleChange =
    (newValue, field) => {
      setTfilters({ ...tFilters, [field]: newValue });
    }

  const handleFilter = useCallback(() => {
    const { query, filters } = tFiltersToFilters(tFilters);
    setFiltersAndQuery(filters, query);
    UserSettingsService.setFilters('XBOXOrder', setFiltersAndQuery, filters);
  }, [setFiltersAndQuery, tFilters]);

  const handleReset = useCallback(() => {
    const { query, filters } = tFiltersToFilters(emptyFilters);
    setFiltersAndQuery(filters, query);
    UserSettingsService.setFilters('XBOXOrder', setFiltersAndQuery, filters);
  }, [setFiltersAndQuery]);

  useEffect(() => {
    handleFilter();
  }, [tFilters]);

  return (
    <>
    <Row className={className} justify={"space-between"}>
      <Col>
        <Button
          className="fr"
          type="dashed"
          onClick={fetchList}
          loading={isLoading}
          icon={<ReloadOutlined />}
          size="large"
        />
      </Col>
      </Row>
      <Row className={className}  justify={"space-between"}>
       {isManager && <Col span={8}>
          <div>
          <Search 
            allowClear
            defaultValue={tFilters.ext} 
            placeholder="Input Order ID" 
            className="mb" 
            size="large" 
            onSearch={(v) => handleChange(v, 'ext')}
          />
          </div>
        </Col>}
      <Col span={7}>
        <RangePicker
          showTime
          size="large"
          onChange={(v) => handleChange(momentToUnixRange(v), 'date')}
          value={
            tFilters.date && tFilters.date.length
              ? tFilters.date.map((v) => unixToMoment(+v))
              : defaultRange.map((d) => unixToMoment(+d))
          }
          style={{ width: '100%' }}
        />
      </Col>
      <Col span={8}>
          <div >
            <StatusSelect
              onChange={(v) => handleChange(v, 'status')}
              value={tFilters.status}
            />
          </div>
        </Col>
    </Row>
    </>
  );
}

Filters.propTypes = {
  className: PropTypes.string,
};

Filters.defaultProps = {
  className: '',
};

export default React.memo(Filters);
