/* eslint-disable camelcase */
import React from 'react';
import b_ from 'b_';
import PropTypes from 'prop-types';
import PrintPrice from '../../../../Components/PrintPrice';
import './styles.scss';

const b = b_.lock('Price');

// eslint-disable-next-line camelcase
function Price({ price, base_price, withSale, ratio }) {
  const isReqPrice = ratio !== 0;

  return (
    <div className={b({ withSale })}>
      {isReqPrice && (
        <div className={b('reqPrice')}>
          <PrintPrice price={price} />
        </div>
      )}
      <div className={b('value', { withReqPrice: isReqPrice })}>
        <PrintPrice price={base_price} />
        {isReqPrice && (
          <span>
            {' '}
            - <b>{Math.round(ratio * 1000) / 10}%</b>
          </span>
        )}
      </div>
    </div>
  );
}

Price.propTypes = {
  price: PropTypes.number.isRequired,
  base_price: PropTypes.number.isRequired,
  withSale: PropTypes.any.isRequired,
  ratio: PropTypes.number.isRequired,
};

export default React.memo(Price);
