import React from 'react';

function PrintPrice({ price }) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(price) || !price) return <>-</>;

  return (
    <>
      {new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(price))}
    </>
  );
}

export default PrintPrice;
