export const regionArray = ['ar', 'glo', 'eu', 'na', 'ru', 'cis'];

export const numberToRegion = {
  1: 'glo',
  2: 'eu',
  3: 'na',
  4: 'ru',
  5: 'cis',
  6: 'ar',
};
