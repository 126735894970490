export function statusToText(status) {
  const parts = status.split('_');

  return parts
    .map((part) => {
      return part.substr(0, 1).toUpperCase() + part.substr(1);
    })
    .join(' ');
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function errorObjectToText(errors) {
  let result = '';

  for (const key in errors) {
    result += `${errors[key].join('\n')}\n`;
  }

  return result;
}

export function generatePassword(length = 8) {
  const chars =
    'abcdefghkmnpqrstuvwxyz23456789ABCDEFGHKMNPQRSTUVWXYZ!@#$%^&*()_';
  let retVal = '';
  for (let i = 0, n = chars.length; i < length; ++i) {
    retVal += chars.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function snakeToCamel(str) {
  return str.replace(/([-_][a-z])/g, (group) =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}
