import React from 'react';
import { Button } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

function AddProductTitle({ disabled, onCreate, onClose }) {
  const loadingAdd = useSelector(
    (state) => state.xbox.priceListCreate.isLoading,
    shallowEqual
  );

  return (
    // <PageHeader
    //   title="Add Product"
    //   onBack={onClose}
    //   style={{ padding: 0 }}
    //   extra={[
        <Button
          size="large"
          icon="plus"
          loading={loadingAdd}
          type="primary"
          disabled={disabled}
          onClick={onCreate}
          key="addButton"
        >
          Add
        </Button>
      // ]}
    // />
  );
}

AddProductTitle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(AddProductTitle);
