import React from 'react';
import { Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const COLOR_TO_OBJ = {
  '1': {
    text: 'new',
    color: 'blue',
  },
  '2': {
    text: 'accepted',
    color: 'green',
  },
  '3': {
    text: 'rejected',
    color: 'red',
  },
  '-1': {
    text: 'empty',
    color: null,
  },
};

function PrintRequestStatus({ status, rejectInfo }) {
  if (status === 3 && rejectInfo) {
    return (
      <Tooltip placement="left" title={rejectInfo}>
        <Tag color={COLOR_TO_OBJ[status].color}>
          {COLOR_TO_OBJ[status].text || 'empty'}
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tag color={COLOR_TO_OBJ[status].color}>
      {COLOR_TO_OBJ[status].text || 'empty'}
    </Tag>
  );
}

PrintRequestStatus.propTypes = {
  status: PropTypes.oneOf(['1', '2', '3', '-1']).isRequired,
  rejectInfo: PropTypes.string,
};

PrintRequestStatus.defaultProps = {
  rejectInfo: '',
};

export default PrintRequestStatus;
