import React, { useEffect } from 'react'
import { Typography } from 'antd';
import s from './styles.module.scss';
import Settings from './Settings';
import { useOutletContext } from 'react-router-dom';
import Page404 from '../Page404';

const { Title } = Typography;

function SettingsGeneral() {

  const isUserAllowed = useOutletContext();
  
  return (
    isUserAllowed ? <div className={s.dashboardSettings}>
      <Title>General Settings</Title>
      <div className={s.layout}>
        <Settings />
      </div>
    </div> :
    <Page404 />
  );
}

export default SettingsGeneral;
