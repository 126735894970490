import uuid5 from 'uuid/v5';
import uuid4 from 'uuid/v4';
import { downloadFile } from '../Utils/apiHelper';
import { snakeToCamel } from '../Utils/textTools';
import {
  wholesaleV2 as ApiWholesaleV2Service,
  merchantV2 as ApiMerchantV2Service,
} from './ApiService';
import { getCurrentCustomerFromLS } from './CoreService';

// function prepareData(items) {
//   return items.map((item) => ({
//     ...item,
//     id: `${item.product_id}:${item.region}`,
//   }));
// }

function newPrepareData(items) {
  return items.map((item) => ({
    ...item,
    id: `${item.productId}:${item.region}`,
  }));
}

function newIdToObject(id) {
  const [productId, region] = id.split(':');

  return {
    productId,
    region,
  };
}

function preparedOrderedItem(item) {
  if (item.products) {
    const currentProducts = [...item.products];
    const newProducts = [];

    currentProducts.forEach((product) => {
      const currentProductInfo = { ...product };
      delete currentProductInfo.items;

      currentProductInfo.countItems = product.items.length;
      currentProductInfo.parentId = `${currentProductInfo.product_id}:${currentProductInfo.region}`;

      product.items.forEach((currentItem, i) => {
        const newData = { ...currentProductInfo, ...currentItem };

        if (i !== 0) {
          delete newData.productId;
          delete newData.region;
          delete newData.game;
        }
        newProducts.push(newData);
      });
    });

    return { ...item, products: newProducts };
  }

  return item;
}

export default {
  orderGames(pltId, ids, count, maxPrice, orderID, immediate, buyFromStock) {
    const items = ids.map((id) => ({
      ...newIdToObject(id),
      qty: count,
      maxPrice,
      pltId: pltId,
    }));
    const reqId = uuid5(JSON.stringify(items), uuid4());
    const q = `&immediate=${immediate}`;
    const currentCustomer = getCurrentCustomerFromLS();

    //https://merchant-router.hoteos.net/api/v2
    return ApiMerchantV2Service.accessPost(
      `/order?customer_id=${currentCustomer.id}${q}`,
      {
        reqId,
        ext: orderID,
        products: items,
        buyFromStock,
      }
    );
  },

  fetchOrderedList(params) {
    const newParams = { ...params };
    const currentCustomer = getCurrentCustomerFromLS();

    if (newParams && newParams.filters.from && newParams.filters.to) {
      newParams.from = newParams.filters.from;
      newParams.to = newParams.filters.to;
      delete newParams.filters.from;
      delete newParams.filters.to;
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessGet(
      `/blizzard/orders?customer_id=${currentCustomer.id || '0'}&`,
      newParams
    );
  },

  //https://wholesale.hoteos.net/api/v2
  fetchRegions() {
    return ApiWholesaleV2Service.accessGet('/blizzard/regions');
  },

  fetchPriceList({ filters = {}, ...params }) {
    const newFilter = {};
    const currentCustomer = getCurrentCustomerFromLS();

    for (const key in filters) {
      newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
        ? filters[key].join(',')
        : filters[key];
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessGet(
      `/blizzard/prices?customer_id=${currentCustomer.id || '0'}&`,
      {
        ...params,
        ...newFilter,
      }
    ).then((data) => ({
      list: newPrepareData(data.page),
      total: data.total,
    }));
  },

  fetchPriceListNonMgr({ filters = {}, ...params }) {
    const newFilter = {};

    for (const key in filters) {
      newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
        ? filters[key].join(',')
        : filters[key];
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessGet(
      `/blizzard/prices`,
      {
        ...params,
        ...newFilter,
      }
    ).then((data) => ({
      list: newPrepareData(data.page),
      total: data.total,
    }));
  },

  //https://merchant-router.hoteos.net/api/v2
  stopOrder(orderId) {
    return ApiMerchantV2Service.accessPatch(`/order/${orderId}/stop`);
  },

  //https://merchant-router.hoteos.net/api/v2
  fetchOrderInfo(orderId) {
    return ApiMerchantV2Service.accessGet(`/order/${orderId}`).then((resp) =>
      preparedOrderedItem(resp)
    );
  },

  //https://wholesale.hoteos.net/api/v2
  getInventoryOrder(orderId) {
    const currentCustomer = getCurrentCustomerFromLS();

    return ApiWholesaleV2Service.accessGet(
      `/blizzard/order/${orderId}/inventory?customer_id=${currentCustomer.id}`
    ).then(downloadFile);
  },

  //https://merchant-router.hoteos.net/api/v2
  checkKeys(orderId) {
    const currentCustomer = getCurrentCustomerFromLS();

    return ApiMerchantV2Service.accessPatch(
      `/order/${orderId}/takekeys?customer_id=${currentCustomer.id}`
    );
  },

  setFavorite(data) {
    const currentCustomer = getCurrentCustomerFromLS();
    const { action, productId, region } = data;

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessPut(
      `/blizzard/prices/tags?customer_id=${currentCustomer.id}`,
      {
        action,
        tags: ['favorites'],
        ids: [{ productId, region }],
      }
    );
  },

  //https://merchant-router.hoteos.net/api/v2
  cancelOrder(orderId) {
    return ApiMerchantV2Service.accessPatch(`/order/${orderId}/cancel`);
  },

  //https://wholesale.hoteos.net/api/v2
  //Platfrom: Blizzard =9
  getQuantityOnStock(pltId, productId, region) {
    return ApiWholesaleV2Service.accessGet(
      `/stock?plt_id=${pltId}&product_id=${productId}&region=${region}`
    );
  },
};
