import React from 'react'
import { Button, Typography } from 'antd';
import s from './styles.module.scss';
import MultipleUsers from '../../Components/PrintMultipleUsers';
import Settings from './Settings';
import { useOutletContext } from 'react-router-dom';
import Page404 from '../Page404';

const { Title } = Typography;

function SettingsUser() {

  const isUserAllowed = useOutletContext();
  
  return (
    isUserAllowed ? <div className={s.dashboardSettings}>
      <Title>Customer Settings</Title>
      <div className={s.layout}>
        {/* <Button>Copy Settings</Button> */}
        <MultipleUsers />
        <Settings />
      </div>
    </div> :
    <Page404 />
  );
}

export default SettingsUser;
