import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import b_ from 'b_';
import cx from '../../Utils/cx';
import './styles.scss';

const b = b_.lock('LoadingBlock');

function LoadingBlock({
  blockRef,
  children,
  loading,
  noMinHeight,
  className,
  contentClassName,
}) {
  return (
    <div className={`${b({ noMinHeight })} ${className}`}>
      <div
        ref={blockRef}
        className={cx(b('content', { loading }), contentClassName)}
      >
        {children}
      </div>
      {loading && (
        <div className={b('spinContainer')}>
          <Spin className={b('spin')} />
        </div>
      )}
    </div>
  );
}

LoadingBlock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  blockRef: PropTypes.any,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  noMinHeight: PropTypes.bool,
  contentClassName: PropTypes.string,
};

LoadingBlock.defaultProps = {
  loading: false,
  noMinHeight: false,
  className: '',
  contentClassName: '',
};

export default LoadingBlock;
