import React from 'react';
import Routes from '../routes';
import './styles.css';
import { ConfigProvider, Spin } from 'antd';
Spin.setDefaultIndicator(<div className='loading-icon'></div>);

function App() {

  const renderRoutes = () => {
    try {
      return <Routes />
    } catch (error) {
      console.log(`ERROR: ${error}`)
    }
  }

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'rgb(131, 73, 246)',
        borderRadius: 12
      },
    }}
  >
  <div className="App">
    {renderRoutes()}
  </div>
  </ConfigProvider>
  );
}

export default App;
