import React from 'react';
import b_ from 'b_';
import { Table } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { regionArray } from '../../../Utils/regions';
import './styles.scss';
import PrintUntil from '../../../Components/PrintUntil';
import PrintRequestStatus from '../../../Components/PrintRequestStatus';
import PrintPrice from '../../../Components/PrintPrice';
import Price from './Price';
import Icon from '@ant-design/icons/lib/components/Icon';

const b = b_.lock('PriceListTable');
const { Column } = Table;

function TablePriceList({
  list,
  isLoading,
  onChange,
  sorter,
  pagination,
  filters,
  selected,
  setSelectedProduct,
  handleOnSelected,
  handleOnSelectAll,
}) {
  const withEdit = useSelector(
    (state) =>
      (state.core.customer &&
        state.core.customer.perm_price_req) ||
      false,
    shallowEqual
  );

  return (
    <Table
      bordered
      className="ant-table-white-bg"
      rowClassName={() => b('row')}
      dataSource={list}
      loading={isLoading}
      onChange={(a, b, c) => onChange(a, b, c)}
      pagination={pagination}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: selected,
        onSelect: handleOnSelected,
        onSelectAll: handleOnSelectAll,
      }}
    >
      <Column title="Sub ID" dataIndex="sub_id" width={150} />
      <Column
        title="Name"
        sorter
        sortOrder={sorter?.field === 'game' ? sorter.order : null}
        dataIndex="game"
      />
      <Column
        width={150}
        title="Region"
        sorter
        sortOrder={sorter?.field === 'region' ? sorter.order : null}
        dataIndex="region"
        filteredValue={filters.region || []}
        filters={regionArray.map((region) => ({
          text: region,
          value: region,
        }))}
      />
      <Column
        title="Price, €"
        sorter
        sortOrder={sorter?.field === 'price' ? sorter.order : null}
        dataIndex="price"
        width={150}
        className="price-column"
        render={(price, rec) => (
          <Price
            price={price}
            withSale={!!rec.sale_price}
            req_status={rec.req_status}
            base_price={rec.base_price || 100}
            ratio={rec.ratio}
          />
        )}
      />
      <Column
        title="Sale Price"
        width={150}
        sorter
        sortOrder={sorter?.field === 'sale_price' ? sorter.order : null}
        filterMultiple={false}
        className="price-column"
        filteredValue={filters.sale_price || []}
        filters={[
          {
            text: 'With Sale',
            value: 1,
          },
          {
            text: 'Without Sale',
            value: 0,
          },
        ]}
        dataIndex="sale_price"
        render={(text) => (text === 0 ? '-' : <PrintPrice price={text} />)}
      />
      <Column
        title="Until"
        width={250}
        sorter
        sortOrder={sorter?.field === 'sale_until' ? sorter.order : null}
        dataIndex="sale_until"
        filterMultiple={false}
        filteredValue={filters.sale_until || []}
        filters={[
          {
            text: 'With Until',
            value: 1,
          },
          {
            text: 'Without Until',
            value: 2,
          },
        ]}
        render={(date) => <PrintUntil date={date} printDiffDays />}
      />
      {withEdit && (
        <Column
          title="Price Request"
          dataIndex="req_status"
          filteredValue={filters.req_status || []}
          width={150}
          filters={[
            {
              text: 'Not Processed',
              value: '1',
            },
            {
              text: 'Special prices',
              value: '2',
            },
            {
              text: 'Rejected',
              value: '3',
            },
          ]}
          render={(text, product) => (
            <>
              <PrintRequestStatus
                status={text || '-1'}
                rejectInfo={product.req_response}
              />
              <Icon
                onClick={() => setSelectedProduct(product)}
                type="message"
                className={b('icon')}
                theme="twoTone"
              />
            </>
          )}
        />
      )}
    </Table>
  );
}

export default TablePriceList;
