import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Select, Tag } from 'antd';

function StatusSelect({
  className,
  value,
  onChange,
  stateReducer,
  fetchRegions,
}) {

  const colors = {
    1: {
      title: 'Created',
      color: 'blue',
    },
    2: {
      title: 'Processing',
      color: 'gold',
    },
    3: {
      title: 'Success',
      color: 'green',
    },
    4: {
      title: 'Rejected',
      color: 'red',
    },
    5: {
      title: 'Expired',
      color: 'purple',
    },
    6: {
      title: 'Canceled',
      color: 'red',
    },
    7: {
      title: 'Interrupted',
      color: 'magenta',
    },
    8: {
      title: 'Waiting Approval',
      color: 'grey',
    },
    9: {
      title: 'Verification',
      color: 'yellow',
    },
  };

  const payload = [
    {
      value: "1", 
      label: "Created",
      color: 'blue'
    },
    {
      value: "2", 
      label: "Processing",
      color: 'gold'
    },
    {
      value: "3", 
      label: "Completed",
      color: 'green'
    },
    {
      value: "4", 
      label: "Rejected",
      color: 'red'
    },
    {
      value: "5", 
      label: "Expired",
      color: 'purple'
    },
    {
      value: "6", 
      label: "Canceled",
      color: 'red'
    },
    {
      value: "7", 
      label: "Interrupted",
      color: 'magenta'
    },
    {
      value: "8", 
      label: "Waiting",
      color: 'grey'
    },
    {
      value: "9", 
      label: "Verification",
      color: 'yellow'
    },
  ]

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={colors[value]?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineStart: 2, padding: '3px 7px', borderRadius: '12px', margin: '4px' }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      // loading={isLoading}
      tagRender={tagRender}
      mode="multiple"
      value={value}
      onChange={onChange}
      // className={'select-status'}
      style={{ width: '100%' }}
      placeholder="Select status"
      allowClear
      size='large'
      options={payload}
    />
      
  );
}

StatusSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stateReducer: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
};

StatusSelect.defaultProps = {
  className: '',
  value: undefined,
};

export default StatusSelect;
