import { snakeToCamel } from '../Utils/textTools';
import ApiService from './ApiService';
import {
  wholesaleV2 as ApiWholesaleV2Service,
  wholesaleV2withMgr as ApiwholesaleV2withMgrService,
  wholesaleV2WithoutPrefix as ApiwholesaleV2withoutPrefix,
} from './ApiService';
import { getCurrentCustomerFromLS } from './CoreService';

function newPrepareData(items) {
  return items.map((item) => ({
    ...item,
    id: `${item.productId}:${item.region}`,
  }));
}

export default {

  getBlackList({ filters = {}, ...params }) {
    const currentCustomer = getCurrentCustomerFromLS();
    const newFilter = {};

    for (const key in filters) {
      newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
        ? filters[key].join(',')
        : filters[key];
    }

    const baseUrl = `/xbox/prices?blacklisted=true&customer_id=${currentCustomer.id || '0'}`;
    const queryString = new URLSearchParams({
      ...params,
      ...newFilter,
    }).toString();
    const url = `${baseUrl}&${queryString}`;

    // Make the API request using the corrected URL
    return ApiWholesaleV2Service.accessGet(url).then((data) => ({
      list: newPrepareData(data.page),
      total: data.total,
    }));
  },

  updateBlackList(data) {
    return ApiwholesaleV2withMgrService.patch('/xbox/prices', [data])
  },

  removeFromBlackList(data) {
    return ApiwholesaleV2withMgrService.delete('/xbox/prices', data)
  },



  set(codes, comment) {
    return ApiService.post('/codes/blacklisted', {
      comment,
      block: true,
      codes,
    });
  },
  removeGiftcode(giftcode) {
    ApiService.delete('/codes/blacklisted', {
      giftcode,
    });

    return new Promise((resolve) => {
      setTimeout(resolve, 1000, {});
    });
  },
};
