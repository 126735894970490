import { message, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  balanceFetchList,
  balanceFetchListNonMgr,
  balanceFetchListReset,
} from '../../Reducers/balance';
import { dateRangePeriods, momentToUnixRange } from '../../Utils/date';
import Filters from './Filters';
import { GenerateCSV, GeneratePDF } from './GenerateButtons';
import Table from './Table';
import { useOutletContext } from 'react-router-dom';

const thisWeek = dateRangePeriods()['This Week'];

/** This is Page */
function Balance() {

  const isManager = useOutletContext();
  const [transactionsData, setTransactionsData] = useState("");
  const [filters, setFilters] = useState({
    dates: momentToUnixRange([thisWeek[0], thisWeek[1]]),
  });


  useEffect(() => {
    if (isManager) {
      setTransactionsData("list")
    } else {
      setTransactionsData("list_account")
    }
  }, [isManager])

  const dispatch = useDispatch();
  useEffect(() => {

    const fetchList = transactionsData === 'list' ? balanceFetchList : balanceFetchListNonMgr
    if (filters.dates.includes(null)) {
      message.info('Please select the dates!');
      return;
    }
    if (transactionsData === 'list') {
    dispatch(balanceFetchList(filters.dates)).catch((error) => {
      if (error.includes('403 Forbidden')) {
        return message.error('Status 403 Forbidden');
      }
      message.error(error.message || error);
    });
  } else if (transactionsData === 'list_account') {
    dispatch(balanceFetchListNonMgr(filters.dates)).catch((error) => {
      if (error.includes('403 Forbidden')) {
        return message.error('Status 403 Forbidden');
      }
      message.error(error.message || error);
    });
  }
  }, [filters, transactionsData]); //eslint-disable-line

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => () => dispatch(balanceFetchListReset()), []);

  return (
    <div>
      <Typography.Title>
        Transactions
      </Typography.Title>
      <Filters
        className="mb"
        defaultFilters={filters}
        setFilters={setFilters}
      />
      {!transactionsData ? <Spin /> :<Table 
      transactionsData={transactionsData}
      />}
    </div>
  );
}

export default Balance;
