import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

function StatusTooltip({ children, status }) {
  if (status === 4 || status === 5) {
    return (
      <Tooltip
        title={
          status === 4
            ? 'Order rejected by user while was in progress'
            : 'Order not completed after timeout'
        }
      >
        {children}
      </Tooltip>
    );
  }

  return children;
}

StatusTooltip.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7]).isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(StatusTooltip);
