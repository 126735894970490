import React from 'react';
import { Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PrintPrice from '../PrintPrice';
import { useEffect } from 'react';

/** Makes a request to the server and displays the current balance of the client */
function CurrentBalance({balance}) {

  const { payload, isLoading } = useSelector(
    (state) => state.core.customer,
    shallowEqual
  );

  // console.log('balance', balance)

  return isLoading ? (
    <Spin size="small" />
  ) : (
    <span>
      <PrintPrice price={balance} /> €
    </span>
  );
}

export default CurrentBalance;
