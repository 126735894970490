import React, { useCallback } from 'react';
import { Col, DatePicker, Row } from 'antd';
import PropTypes from 'prop-types';
import {
  DEFAULT_FORMAT,
  momentToUnixRange,
  unixToMoment,
} from '../../../Utils/date';

const { RangePicker } = DatePicker;

function Filters({ defaultFilters, setFilters, className }) {
  const handleChange = useCallback(
    (dates) => {
      setFilters({
        query: '',
        dates: dates.length ? momentToUnixRange(dates) : [null, null],
      });
    },
    [setFilters]
  );

  return (
    <Row className={className} gutter={16}>
      <Col span={10}>
        <RangePicker
          showTime={false}
          size="large"
          value={defaultFilters.dates.map(unixToMoment)}
          onChange={handleChange}
          style={{ width: '100%' }}
          format={DEFAULT_FORMAT}
        />
      </Col>
    </Row>
  );
}

Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  className: PropTypes.string,
  defaultFilters: PropTypes.shape({
    dates: PropTypes.arrayOf(PropTypes.number),
  }),
};

Filters.defaultProps = {
  className: '',
  defaultFilters: {
    dates: [null, null],
  },
};

export default Filters;
