import React from 'react';
import './styles.scss';

function ColumnProducts({ products }) {
  return (
    <div className="ColumnProducts">
      {products.map((product) => (
        <div key={product}>{product}</div>
      ))}
    </div>
  );
}

export default React.memo(ColumnProducts);
