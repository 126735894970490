import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Select, message, Button } from 'antd';
import PropTypes from 'prop-types';
import { setCurrentCustomerToLS, getCurrentCustomerFromLS} from '../../Services/CoreService'
import { coreFetchCustomers, coreSetCurrentCustomer} from '../../Reducers/core';
import { copySettingsToCustomers } from '../../Reducers/settings';

/** Displays the user name */
function MultipleUsers({ className }) {

  const [selectedCustomers, setSelectedCustomers] = useState([])
  const payload = useSelector((state) => state.core.customers.payload, shallowEqual);
  // const sortedList = payload && payload.sort((a, b) => {
  //   const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //   const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  
  //   // names must be equal
  //   return 0;
  // });

  // console.log('sortedList', sortedList)

  const dispatch = useDispatch();

  let currentCustomer = getCurrentCustomerFromLS()

  useEffect(() => {
    dispatch(coreFetchCustomers())
    .then((res) => {
      const customerFromLS = getCurrentCustomerFromLS()
      const customer = Object.keys(customerFromLS).length !== 0 ? customerFromLS : res.value[0]
      setCurrentCustomerToLS(customer)
      dispatch(coreSetCurrentCustomer(customer))
    })
    .catch((error) => {
      message.error(error.message || error);
    });
  }, []); //eslint-disable-line

  const onSelect = (id) => {
    // const selectedCustomer = payload.find((customer) => customer.id === id)
    setSelectedCustomers(id)
  }

  const handleCopySettings = () => {
    let copiedObject = {'from': currentCustomer.id, 'to': selectedCustomers}

    dispatch(copySettingsToCustomers(copiedObject))
    .then(() => {
      message.success('Settings have been copied to customers!');
    })
    .catch((error) => {
      console.log('error', error)
      // message.error(`Failed to copy settings: ${error.message}`)
    })
  }

  return (
    <>
    <Button onClick={handleCopySettings}>Copy Settings</Button>
    <Select
      mode="multiple"
      className={className}
      // size="small"
      style={{ width: '500px', marginLeft: '20px' }}
      placeholder="Demo"
      allowClear
      onChange={onSelect}
    >
    {payload && payload.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          { item.name }
        </Select.Option>
      ))}
    </Select>
    </>
  );
}

MultipleUsers.propTypes = {
  className: PropTypes.string,
};

MultipleUsers.defaultProps = {
  className: '',
};

export default MultipleUsers;
