import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';

function FilterUpdateAtIcon(filtered) {
  return (
    <Icon
      type="filter"
      theme="filled"
      style={{ color: filtered ? '#1890ff' : '#bfbfbf' }}
    />
  );
}

export default FilterUpdateAtIcon;
