import { Typography } from 'antd'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchBlackListItems } from '../../Reducers/blackList'
import useTable from '../../Utils/useTable'
import TableGameList from '../Price/TableGameList'
import { useOutletContext } from 'react-router-dom'
import Page404 from '../Page404'

const { Title } = Typography

const BlackListProducts = () => {

  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.blacklist.fetchList, fetchBlackListItems);

  const context = useOutletContext();

  return (
    context ? <div>
    <Title>Black List</Title>
    <a href="/" className="blacklist_link__back">{"<"} Back to Prices</a>
    <TableGameList
            isBlizzard={false}
            blacklisted={false}
            list={list.list}
            isLoading={isLoading}
            pagination={pagination}
            setFavorites={null}
            getQuantityOnStock={null}
            selections
            onChange={(a, b, c) => onChange(a, b, c)}
            // sorter={sorter}
            stateReducer={(state) => state.xbox}
            orderGames={null}
            orderGamesReset={null}
          />
    </div> : <Page404 />
  )
}

export default BlackListProducts
