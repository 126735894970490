import BlackListService from '../Services/BlackListService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchBlackListItems = createAction('blackList/fetchList', (params) => ({
  payload: BlackListService.getBlackList(params)
}))

export const blackListAdd = createAction('blackList/add', (codes, comment) => ({
  payload: BlackListService.set(codes, comment)
}))

export const blackListUpdate = createAction('blackList/update', (data) => ({
  payload: BlackListService.updateBlackList(data)
}))

export const blackListDelete = createAction('blackList/delete', (data) => ({
  payload: BlackListService.removeFromBlackList({ ...data, blacklisted: false })
}))

export const blackListAddReset = createAction('blackList/blackList/add_RESET')

export const blackListRemoveGiftcode = createAction('blackList/remove-giftcode', (giftcode) => ({
  payload: BlackListService.removeGiftcode(giftcode)
}))

export const blackListRemoveGiftcodeReset = createAction('blackList/remove-giftcode_RESET')

const initState = {
  fetchList: getDefaultState(),
  update: getDefaultState(),
  delete: getDefaultState(),
  set: getDefaultState(),
  removeGiftcode: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchBlackListItems, 'fetchList'),
  ...getDefaultHandler(blackListUpdate, 'update'),
  ...getDefaultHandler(blackListDelete, 'delete'),
  ...getDefaultHandler(blackListAdd, 'set'),
  ...getDefaultHandler(blackListRemoveGiftcode, 'removeGiftcode')
})
