import { Table } from 'antd';
import b_ from 'b_';
import React, { useCallback } from 'react';
import cx from '../../../Utils/cx';
import useWindowSize from '../../../Utils/useWindowSize';
import { getColumns } from './Utils';
import './styles.scss';

const b = b_.lock('TableOrdered');

function TableOrdered({
  list,
  onChange,
  pagination,
  isLoading,
  fetch,
  stateReducer,
  checkCodes,
  cancelOrder,
  getInventoryOrder,
  fetchOrderInfo,
  stopOrder,
  stopOrderReset,
}) {
  const [width] = useWindowSize();

  const getRowClassName = useCallback((record) => {
    return record.products &&
      record.products.length &&
      record.products.length > 1
      ? ''
      : 'ant-table-row--hide-expand';
  }, []);

  return (
    <Table
      className={cx('ant-table-white-bg', b())}
      bordered
      rowKey="order_id"
      scroll={{ y: "calc(100vh - 150px)", x: "max-content" }}
      dataSource={list}
      onChange={(a, b, c) => onChange(a, b, c)}
      rowClassName={getRowClassName}
      columns={getColumns(
        b,
        fetch,
        stateReducer,
        checkCodes,
        cancelOrder,
        getInventoryOrder,
        fetchOrderInfo,
        stopOrder,
        stopOrderReset
      )}
      pagination={pagination}
      loading={isLoading}
    />
  );
}

export default TableOrdered;
