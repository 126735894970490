import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

function ShowSelectedGamesButton({ toggleShowSelected, keys, isShowSelected }) {
  return (
    <Button
      block
      size="large"
      className="mb"
      type="dashed"
      onClick={() => toggleShowSelected()}
    >
      You have selected&nbsp;<b>{keys.length}</b>
      &nbsp;
      {keys.length === 1 ? 'game' : 'games'}. Click to&nbsp;
      <b>{isShowSelected ? 'hide' : 'show'}</b>
    </Button>
  );
}

ShowSelectedGamesButton.propTypes = {
  toggleShowSelected: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  keys: PropTypes.array,
  isShowSelected: PropTypes.bool.isRequired,
};

ShowSelectedGamesButton.defaultProps = {
  keys: [],
};

export default ShowSelectedGamesButton;
