import CoreService from '../Services/CoreService';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const coreFetchCustomers = createAction('core/customers', () => ({
  payload: CoreService.fetchCustomers(),
}))

export const coreFetchSelectedCustomers = createAction('core/customersSelected', () => ({
  payload: CoreService.fetchCustomersSelected(),
}))

export const fetchSelectedCustomers = createAction('core/customersSettingsSelected', () => ({
  payload: CoreService.fetchCustomersSettings(),
}))

export const coreSetSelectedCustomers = createAction('core/setCustomersSettingsSelected', (data) => ({
  payload: CoreService.changeCustomersSettings(data),
}))

export const fetchAccountData = createAction('core/account', () => ({
  payload: CoreService.fetchAccountData()
}))

export const setCreditLimit = createAction('core/creditLimit', (data) => ({
  payload: CoreService.setCustomerCreditLimit(data)
}))

export const coreSetCurrentCustomer = createAction('core/customer', (customer) => ({
  payload: customer,
}))

const initState = {
  customers: getDefaultState(),
  customersSelected: getDefaultState(),
  customersSettingsSelected: getDefaultState(),
  setCustomersSettingsSelected: getDefaultState(),
  account: getDefaultState(),
  creditLimit: getDefaultState(),
  customer: ''
};

export default createReducer(initState, {
  ...getDefaultHandler(coreFetchCustomers, 'customers'),
  ...getDefaultHandler(coreFetchSelectedCustomers, 'customersSelected'),
  ...getDefaultHandler(fetchSelectedCustomers, 'customersSettingsSelected'),
  ...getDefaultHandler(coreSetSelectedCustomers, 'setCustomersSettingsSelected'),
  ...getDefaultHandler(fetchAccountData, 'account'),
  ...getDefaultHandler(setCreditLimit, 'creditLimit'),
  [coreSetCurrentCustomer]: (state, action) => {
    state.customer = action.payload;
  },
})
