import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Table } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PrintUntil from '../../../Components/PrintUntil';
import PrintPrice from '../../../Components/PrintPrice';
import getXBOXStoreGameLink from '../../../Utils/getXBOXStoreGameLink';
import OrderButton from '../../../Components/Xbox/OrderButton';
import Icon from '@ant-design/icons/lib/components/Icon';
import AddToBlackList from '../../../Components/AddToBlackList';

const { Column } = Table;

function TableGameList(props) {
  // eslint-disable-next-line no-unused-vars
  const {
    isBlizzard,
    blacklisted,
    list,
    isLoading,
    onChange,
    pagination,
    rowSelection,
    sorter,
    isViewFiltersAndButton,
    setFavorites,
    getQuantityOnStock,
    stateReducer,
    orderGames,
    orderGamesReset,
    afterUpdate,
    isUserAllowed
  } = props;
  const dispatch = useDispatch();
  const regions = useSelector(
    (state) => stateReducer(state).regions,
    shallowEqual
  );

  // useEffect(() => {
  //   if (!regions.payload && !regions.isLoading) {
  //     dispatch(fetchRegions());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [regions]);

  return (
    <>
      <Table
        scroll={{ x: "max-content" }}
        bordered
        className="ant-table-white-bg"
        dataSource={list}
        loading={isLoading}
        rowSelection={rowSelection}
        onChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        rowKey="id"
        {...props}
      >
        <Column
          title="Product ID"
          dataIndex="productId"
          width={180}
          render={(id) => <small>{id}</small>}
        />
        <Column
          title="Name"
          dataIndex="name"
          width={300}
          sorter={isViewFiltersAndButton}
          sortOrder={sorter?.field === 'name' ? sorter.order : null}
          render={(name, { productId, region }) => (
            <a
              href={getXBOXStoreGameLink(productId, region)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          )}
        />
        <Column
          title="Region"
          dataIndex="region"
          width={99}
          sorter={isViewFiltersAndButton}
          sortOrder={sorter?.field === 'region' ? sorter.order : null}
        />
        <Column
          title="Amount"
          dataIndex="prices"
          width={85}
          align="right"
          render={(prices) => (
            <span>
              {prices.reduce((acc, cur) => (cur.amount || 0) + acc, 0)}
            </span>
          )}
        />
        <Column
          title="Price, €"
          width={110}
          dataIndex="price"
          sorter={isViewFiltersAndButton}
          sortOrder={sorter?.field === 'price' ? sorter.order : null}
          className="price-column"
          render={(_, { prices }) => (
            <PrintPrice price={prices && prices[0] && prices[0].value || 0} />
          )}
        />
        <Column
          title="Sale, %"
          dataIndex="salePct"
          width={110}
          sorter={isViewFiltersAndButton}
          sortOrder={sorter?.field === 'salePct' ? sorter.order : null}
          align="right"
        />
        <Column
          title="Until, (UTC)"
          dataIndex="saleUntil"
          width={140}
          sorter={isViewFiltersAndButton}
          sortOrder={sorter?.field === 'saleUntil' ? sorter.order : null}
          className="price-column"
          render={(saleUntil) => (
            <PrintUntil date={saleUntil} withTime withUtc />
          )}
        />
        {setFavorites && (
          <Column
            title="&#9733;"
            dataIndex="tags"
            width={40}
            align="center"
            render={(tags = [], { region, productId }) => (
              <Icon
                type="star"
                style={{ color: '#D4AF37' }}
                onClick={() =>
                  setFavorites({
                    region,
                    productId,
                    action: tags.includes('favorites') ? 'remove' : 'add',
                  })
                }
                theme={tags.includes('favorites') && 'filled'}
              />
            )}
          />
        )}
        {isViewFiltersAndButton && (blacklisted || isBlizzard) && (
          <Column
            width={60}
            align="center"
            render={(_, product) => (
              <OrderButton
                pltId={product.pltId}
                productId={product.productId}
                region={product.region}
                game={product.name}
                defaultMaxPrice={product.prices && product.prices[0] && product.prices[0].value}
                prices={product.prices}
                stateReducer={stateReducer}
                orderGames={orderGames}
                orderGamesReset={orderGamesReset}
                getQuantityOnStock={getQuantityOnStock}
                isUserAllowed={isUserAllowed}
              />
            )}
          />
        )}
          {isBlizzard === false || isUserAllowed && <Column
            title="BL"
            width={60}
            align="center"
            render={(_, product) => (
              <AddToBlackList
                blacklisted={blacklisted}
                pltId={product.pltId}
                productId={product.productId}
                region={product.region}
                isLoading={isLoading}
                afterUpdate={afterUpdate}
              />
            )}
          />}
      </Table>
    </>
  );
}

TableGameList.propTypes = {
  isViewFiltersAndButton: PropTypes.bool,
  scrollY: PropTypes.number,
  stateReducer: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  orderGames: PropTypes.func.isRequired,
  orderGamesReset: PropTypes.func.isRequired,
};

TableGameList.defaultProps = {
  isViewFiltersAndButton: true,
  scrollY: null,
};

export default TableGameList;
