import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const CheckboxGroup = Checkbox.Group;

const regionsMock = {
  steam: [
    {
      label: 'ar',
      value: 'ar',
      disabled: false,
    },
    {
      label: 'glo',
      value: 'glo',
      disabled: true,
    },
    {
      label: 'na',
      value: 'na',
      disabled: true,
    },
    {
      label: 'ru',
      value: 'ru',
      disabled: true,
    },
    {
      label: 'eu',
      value: 'eu',
      disabled: true,
    },
  ],
  xbox: [],
};

function RegionSelector({
  onChange,
  selected,
  className,
  type,
  stateReducer,
  fetchRegions,
}) {
  const dispatch = useDispatch();
  const [regions, setRegions] = useState(regionsMock);

  const XBOXregionsState = useSelector(
    (state) => stateReducer(state).regions,
    shallowEqual
  );

  useEffect(() => {
    if (
      type === 'xbox' &&
      !XBOXregionsState.payload &&
      !XBOXregionsState.isLoading
    ) {
      dispatch(fetchRegions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [XBOXregionsState, type]);

  const handleUpdateXBOXRegions = useCallback(
    (data) => {
      setRegions({
        ...regions,
        xbox: data.payload.map((item) => {
          return {
            label: item,
            value: item,
            disabled: false,
          };
        }),
      });
    },
    [regions]
  );

  useEffect(() => {
    if (XBOXregionsState.payload && !XBOXregionsState.isLoading) {
      handleUpdateXBOXRegions(XBOXregionsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [XBOXregionsState]);

  return (
    <CheckboxGroup
      className={className}
      options={regions[type]}
      value={selected}
      onChange={onChange}
    />
  );
}

RegionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['steam', 'xbox']),
  stateReducer: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  // defaultSelectedAll: PropTypes.bool,
};

RegionSelector.defaultProps = {
  className: '',
  type: 'steam',
  // defaultSelectedAll: false,
};

export default React.memo(RegionSelector);
