import React from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { utcToDefaultFormat } from '../../../Utils/date';
import PrintPrice from '../../../Components/PrintPrice';

const { Column } = Table;

function PrintBalance({ balance }) {
  return (
    <span style={balance < 0 ? { color: 'red' } : null}>
      <PrintPrice price={balance} />
    </span>
  );
}

PrintBalance.propTypes = {
  balance: PropTypes.number.isRequired,
};

function PrintAmount({ amount }) {
  return (
    <span style={amount > 0 ? { color: 'green' } : null}>
      <PrintPrice price={amount} />
    </span>
  );
}

PrintAmount.propTypes = {
  amount: PropTypes.number.isRequired,
};

function BalanceTable({transactionsData}) {
  const { payload, isLoading } = useSelector((state) => state.balance[transactionsData]);

  return (
    <Table
      bordered
      className="ant-table-white-bg"
      dataSource={(payload && payload.page) || null}
      loading={isLoading}
      pagination={false}
      rowKey={(obj) =>
        `balance_${obj.id}`
      }
    >
      <Column
        title="Order ID"
        dataIndex="orderId"
        render={(order) => <span>{order}</span>}
      />
      <Column
        title="Date"
        dataIndex="day"
        render={(date) => utcToDefaultFormat(date)}
      />
      <Column
        title="Created"
        dataIndex="cre"
        render={(date) => utcToDefaultFormat(date)}
      />
      <Column
        title="Amount"
        dataIndex="amount"
        render={(amount) => <PrintAmount amount={amount} />}
      />
      <Column
        title="Type"
        dataIndex="typeStr"
        render={(type) => <span>{type}</span>}
      />
      <Column
        title="Notes"
        dataIndex="notes"
        render={(notes) => <span>{notes}</span>}
      />
    </Table>
  );
}

export default BalanceTable;
