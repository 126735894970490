import { Button, message } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';

function GetInventoryOrderButton({ orderId, stateReducer, getInventoryOrder, className }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => stateReducer(state).inventoryOrder.isLoading
  );

  const handleDownload = useCallback(() => {
    dispatch(getInventoryOrder(orderId)).catch((error) => {
      message.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return (
    <Button
      size="small"
      className={className}
      onClick={handleDownload}
      type="primary"
      icon={<DownloadOutlined />}
    >
      Download
    </Button>
  );
}

GetInventoryOrderButton.propTypes = {
  orderId: PropTypes.number.isRequired,
  stateReducer: PropTypes.func.isRequired,
  getInventoryOrder: PropTypes.func.isRequired,
};

export default React.memo(GetInventoryOrderButton);
