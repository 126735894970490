import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import StatusTooltip from './StatusTooltip';

const colors = {
  1: {
    title: 'Created',
    color: 'blue',
  },
  2: {
    title: 'Processing',
    color: 'gold',
  },
  3: {
    title: 'Success',
    color: 'green',
  },
  4: {
    title: 'Rejected',
    color: 'red',
  },
  5: {
    title: 'Expired',
    color: 'purple',
  },
  6: {
    title: 'Canceled',
    color: 'red',
  },
  7: {
    title: 'Interrupted',
    color: 'magenta',
  },
  8: {
    title: 'Waiting Approval',
    color: 'grey',
  },
  9: {
    title: 'Verification',
    color: 'yellow',
  },
};

function PrintStatusOrder({ status }) {
  if (status) {
    return (
      <StatusTooltip status={status}>
        <Tag
          color={(colors[status] && colors[status].color) || 'lime'}
          className="mr-clear"
        >
          {(colors[status] && colors[status].title) || status}
        </Tag>
      </StatusTooltip>
    );
  }
  return '';
}

PrintStatusOrder.propTypes = {
  status: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]).isRequired,
};

export default PrintStatusOrder;
