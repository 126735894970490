import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { priceListUpdatePrice } from '../../../Reducers/priceList';

function UpdatePriceModal({ product, close }) {
  const loading = useSelector((state) => state.priceList.updatePrice.isLoading);
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPrice(product ? product.req_price || product.price : 0);
  }, [product]);

  const updatePrice = useCallback(() => {
    dispatch(priceListUpdatePrice(product.id, price))
      .then(() => {
        message.success('Price request has been created!');
        close();
      })
      .catch((error) => {
        message.error(error);
      });
  }, [price, product]); //eslint-disable-line

  return (
    <Modal
      visible={!!product}
      onOk={updatePrice}
      title="Update Price"
      onCancel={close}
      cancelButtonProps={{ disabled: loading }}
      confirmLoading={loading}
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: price <= 0,
      }}
      destroyOnClose
    >
      <div className="mb-small">
        <b>Current Price:</b>
      </div>
      <InputNumber
        defaultValue={product ? product.price : 0}
        className="mb"
        readOnly
        style={{ width: '100%' }}
      />
      <div className="mb-small">
        <b>New Price:</b>
      </div>
      <InputNumber
        value={price}
        onChange={setPrice}
        autoFocus
        style={{ width: '100%' }}
      />
    </Modal>
  );
}

export default UpdatePriceModal;
