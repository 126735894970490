import React from 'react';
import b_ from 'b_';
import PropTypes from 'prop-types';
import './styles.scss';

const b = b_.lock('AuthLayout');

function AuthLayout({ children, className }) {
  return (
    <div className={b()}>
      <div className={[b('inner'), className].join(' ')}>{children}</div>
    </div>
  );
}

AuthLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AuthLayout.defaultProps = {
  className: '',
};

export default AuthLayout;
