import React from 'react'
import { useDispatch } from 'react-redux'
import { blackListUpdate } from '../../Reducers/blackList'
import { Button, Popconfirm, Tooltip, message } from 'antd'
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons'

const AddToBlackList = ({ blacklisted, pltId, productId, region, isLoading, afterUpdate }) => {

  const dispatch = useDispatch()

  const handleAddToBlackList = () => {
    dispatch(blackListUpdate({pltId, productId, region, blacklisted}))
      .then((res) => {
        message.success(`Item has been ${!blacklisted ? "removed from" : "added to"} black list!`)
      })
      .catch((err) => {
        message.error(`Failed to update black list! ${err}`)
      })
      .finally(() => {
        afterUpdate()
      })
  }


  return (
    <Popconfirm
      title={`${!blacklisted ? "Remove item from" : "Add item to"} black list`}
      onConfirm={handleAddToBlackList}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title={`${!blacklisted ? "Remove from" : "Add to"} black list`}>
      <Button
        danger={blacklisted}
        type="primary"
        icon={!blacklisted ? <CheckCircleOutlined /> : <StopOutlined />}
        loading={isLoading}
        size="small"
      />
      </Tooltip>
    </Popconfirm>
  )
}

export default AddToBlackList
