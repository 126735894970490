import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { verifyOrder } from '../../../Reducers/xbox';
import { CheckCircleOutlined } from '@ant-design/icons';

function VerifyButton({ className, afterSuccess, orderId }) {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(verifyOrder(orderId))
    .then(() => {
      message.success(`Order has been verified`);
    })
    .catch((error) => {
      message.error(`Failed to verify: ${error || error.message}`);
    })
    .finally(() => {
      afterSuccess();
    })
  }, [dispatch, orderId, afterSuccess]);

  return (
    <Button
      size="small"
      type="primary"
      className={[className, 'button_success']}
      onClick={handleClick}
      icon={<CheckCircleOutlined />}
    >
      Verify
    </Button>
  );
}

VerifyButton.propTypes = {
  className: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  afterSuccess: PropTypes.func.isRequired,
  checkCodes: PropTypes.func.isRequired,
};

VerifyButton.defaultProps = {
  className: '',
};

export default VerifyButton;
