import FormItem from 'antd/es/form/FormItem';
import b_ from 'b_';
import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Form, message, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  blackListAdd,
  blackListAddReset,
  blackListRemoveGiftcodeReset,
} from '../../Reducers/blackList';
import { getClearCodesArray } from '../../Utils/giftcode';
import './styles.scss';

const b = b_.lock('BlackList');

function printError(array) {
  return (
    <div className={b('error')}>
      {array && typeof array === 'object'
        ? array.map((elem, i) => {
            if (elem.code && elem.error) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${i}_${elem.code}`} className={b('error__item')}>
                  <div className={b('error__code')}>{elem.code}</div>
                  <b> - {elem.error}</b>
                </div>
              );
            }
            return null;
          })
        : array}
    </div>
  );
}

function checkEmptiness(rule, value, callback) {
  if (value && value.length > 0 && value.trim().length === 0) {
    callback(rule.message);
  }
  callback();
}

/** This is Page */
function BlackList({ form }) {
  const { getFieldDecorator } = form;
  const { isLoading, error } = useSelector(
    (state) => state.blacklist.set,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(blackListAddReset());
      dispatch(blackListRemoveGiftcodeReset());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmitBlock = useCallback(() => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch(
          blackListAdd(getClearCodesArray(values.codes), values.comment.trim())
        ).then(() => {
          message.success('Success!');
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Typography.Title>Black List</Typography.Title>
      {error && (
        <Alert
          message="Errors:"
          description={printError(error)}
          type="error"
          showIcon
          className="mb"
        />
      )}

      <Form onSubmit={handleSubmitBlock}>
        <FormItem label="Codes for black list">
          {getFieldDecorator('codes', {
            rules: [
              {
                required: true,
                message: 'Please input codes!',
              },
              {
                validator: checkEmptiness,
                message: 'Please input codes!',
              },
            ],
          })(
            <TextArea
              style={{ resize: 'none' }}
              className="mb"
              placeholder="Codes for black list"
              rows={5}
            />
          )}
        </FormItem>
        <FormItem label="Comment">
          {getFieldDecorator('comment', {
            rules: [
              {
                required: true,
                message: 'Please input comment!',
              },
              {
                validator: checkEmptiness,
                message: 'Please input comment!',
              },
            ],
          })(
            <TextArea
              style={{ resize: 'none' }}
              className="mb"
              placeholder="Comment"
              rows={5}
            />
          )}
        </FormItem>
        <FormItem>
          <Button
            htmlType="submit"
            type="danger"
            icon="delete"
            size="large"
            block
            loading={isLoading}
          >
            Add in Black List
          </Button>
        </FormItem>
      </Form>
    </div>
  );
}

export default BlackList;
