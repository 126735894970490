import { Col, InputNumber, Modal, Row, Slider } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

/** Modal window for selecting the number of links */
function GetLinksModal({ visible, close, onOk, number, setNumber, loading }) {
  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={onOk}
      closable={false}
      maskClosable={false}
      title="Amount of links:"
      okButtonProps={{
        disabled: number < 1 || number > 200,
      }}
      cancelButtonProps={{ disabled: loading }}
      confirmLoading={loading}
    >
      <Row>
        <Col span={18}>
          <Slider
            min={1}
            max={200}
            onChange={setNumber}
            value={typeof number === 'number' ? number : 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={1}
            max={200}
            className="ml"
            value={number}
            onChange={(v) => {
              let n = Math.round(v);
              if (n < 1) n = 1;
              if (n > 200) n = 200;
              setNumber(n);
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}

GetLinksModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  setNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GetLinksModal;
