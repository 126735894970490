import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Slider, Spin, Tooltip, message } from 'antd'
import React, { useCallback, useEffect } from 'react'
import useBoolean from '../../Utils/useBoolean';
import { orderFormFetch, orderManualApprove } from '../../Reducers/xbox';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LikeOutlined } from '@ant-design/icons';

const PrintManualApproveOrder = ({ orderId, className }) => {

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);


  const [quantity, setQty] = useState(1);
  const [orderForm, setOrderForm] = useState({})

  const dispatch = useDispatch();

  const { payload, isLoading } = useSelector((state) => state.xbox.fetchOrderForm, shallowEqual);

  const handleOnClick = () => {
    dispatch(orderFormFetch(orderId))
      .then((res) => {
      })
      .catch((err) => message.error(`Failed to fetch order info! ${err}`))
    open()
  }

  useEffect(() => {
    if (payload) {
      setOrderForm({
          game: payload.products?.[0].name,
          region: payload.products?.[0].region,
          pltId: payload.products?.[0].pltId,
          productId: payload.products?.[0].productId,
          price: payload.products?.[0]?.price,
          immediate: payload.immediate,
          quantityOnStock: payload.products?.[0]?.qty

        });
    }
  }, [payload]);

  const { game, region, pltId, productId, price, immediate, quantityOnStock } = orderForm

  const handleExecuteOrder = useCallback(() => {
    if (quantity > quantityOnStock) {
      message.error(`Quantity shouldn't exceed ${quantityOnStock}`)
    } else {
      dispatch(orderManualApprove([{ pltId, productId, qty: quantity, region }], orderId))
      .then(() => {
        message.success(`Succefully executed order!`);
        close()
      })
      .catch((error) => {
        message.error(`Failed to execute order: ${error}`)
      })
    }
  }, [quantity, orderForm])

  return (
    <>
      <Tooltip title="Approve order">
        <Button
          type="primary"
          size="small"
          onClick={handleOnClick}
          loading={isLoading}
          className={[className, 'button_success']}
          icon={<LikeOutlined />}
        >Approve</Button>
      </Tooltip>
      <Modal
      loading={isLoading}
      isLoading={isLoading}
      destroyOnClose
      visible={visible}
      onCancel={close}
      onOk={handleExecuteOrder}
      closable={false}
      maskClosable
      title={`Order Keys: ${game || ''} (${region})`}
      okButtonProps={{
        disabled:
        quantity < 1 ||
        quantity > 500 ||
        quantityOnStock < quantity ||
          // number > (buyFromStock ? stockAmount : 500) ||
          !price,
      }}
      cancelButtonProps={{ disabled: isLoading }}
      confirmLoading={isLoading}
    >
      {isLoading ? <span><Spin />.</span> :
      <>
      <Row className="mb-small">
        <Col span={4} className="flex-left" style={{ minHeight: '32px' }}>
          <b>Quantity:</b>
        </Col>
        <Col span={15}>
          <Slider
            min={1}
            max={500}
            onChange={setQty}
            value={typeof quantity === 'number' ? quantity : 0}
          />
        </Col>
        <Col span={5}>
          <InputNumber
            min={1}
            max={500}
            className="ml fr"
            value={quantity}
            onChange={(v) => {
              let n = Math.round(v);
              if (n < 1) n = 1;
              const max = 500;
              if (n > max) n = max;
              setQty(n);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-small">
        <Col span={19} className="flex-left" style={{ minHeight: '32px' }}>
          <b>Sell price, €:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <InputNumber
            readOnly={true}
            className="fr"
            min={0.01}
            value={price}
            precision={2}
          />
        </Col>
      </Row>
      <Row className="flex-left mb-small">
        <Col span={6}>
          <b>Order ID:</b>
        </Col>
        <Col span={18}>
          <Input
            readOnly={true}
            value={orderId}
            type="text"
            className="w100p"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} >
        <Col span={19} className="flex-left" >
          <b>Immediate</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <Checkbox
            readOnly={true}
            className="fr"
            checked={immediate}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} >
        <Col span={19} className="flex-left" >
          <b>Quantity on stock:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }} >
          <span style={{ float:"right" }}>{quantityOnStock}</span>
        </Col>
      </Row></>}

    </Modal>
    </>
  )
}

export default PrintManualApproveOrder
