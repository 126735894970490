import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Table from './Table';
import useBoolean from '../../Utils/useBoolean';
// import ListWrapper from '../../HOC/ListWrapper';
import { activationsFetchList } from '../../Reducers/activations';

/** This is Page */
function Activations({ pagination, onChange, fetchList, filters }) {
  const [codes, setCodes] = useState('');
  const {
    value: isSearchButtonDisabled,
    setTrue: searchButtonDisabled,
    setFalse: searchButtonUnDisabled,
  } = useBoolean(true);
  const {
    value: isViewSearch,
    setTrue: changeViewToSearch,
    setFalse: changeViewToList,
  } = useBoolean(false);
  const { isLoading, payload: activations } = useSelector(
    (state) => state.activations.list,
    shallowEqual
  );

  const {
    isLoading: isLoadingRemoveGiftcode,
    payload: payloadRemoveGiftCode,
  } = useSelector((state) => state.blacklist.removeGiftcode, shallowEqual);

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadRemoveGiftCode]);

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (e) => {
      setCodes(e.target.value);
    },
    [setCodes]
  );

  useEffect(() => {
    if (!codes.length) return searchButtonDisabled();
    searchButtonUnDisabled();
  }, [codes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = useCallback(() => {
    changeViewToSearch();
    dispatch(activationsFetchList(null, codes)).catch((error) => {
      message.error(error.message || error);
    });
  }, [dispatch, codes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClear = useCallback(() => {
    setCodes('');
    if (isViewSearch)
      dispatch(activationsFetchList(pagination)).catch((error) => {
        message.error(error.message || error);
      });
    changeViewToList();
  }, [dispatch, setCodes, isViewSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Typography.Title>
        Activations
        <div className="fr">
          <Button
            className="mr-small"
            type="primary"
            icon="search"
            size="large"
            onClick={handleSearch}
            disabled={isSearchButtonDisabled}
          >
            Search
          </Button>
          <Button
            type="danger"
            icon="delete"
            size="large"
            onClick={handleClear}
            disabled={isSearchButtonDisabled && !isViewSearch}
          >
            Clear Filter
          </Button>
        </div>
      </Typography.Title>
      <TextArea
        style={{ resize: 'none' }}
        className="mb"
        placeholder="Codes for search"
        onChange={handleChange}
        rows={5}
        value={codes}
        onError={handleSearch}
      />
      <Table
        filters={filters}
        pagination={pagination}
        onChange={onChange}
        isLoading={isLoading || isLoadingRemoveGiftcode}
        onPagination={activations && !!activations.total}
        list={activations && activations.total ? activations.list : activations}
      />
    </div>
  );
}

export default Activations;

// export default ListWrapper(
//   Activations,
//   (store) => store.activations.list,
//   activationsFetchList
// );
