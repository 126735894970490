import { wholesaleV2withMgr as ApiwholesaleV2withMgrService } from './ApiService';
import { getCurrentCustomerFromLS } from './CoreService';

export default {
  fetchSettings() {
    // data
    return ApiwholesaleV2withMgrService.accessGet(`/configs`);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockSettings);
    // });
  },

  fetchSettingsSchema() {
    return ApiwholesaleV2withMgrService.accessGet(`/configs/schema`);
  },
  fetchSettingsUISchema() {
    return ApiwholesaleV2withMgrService.accessGet(`/configs/uischema`);
  },
  updateSettings(data) {
    return ApiwholesaleV2withMgrService.accessPut(`/configs`, data);
  },

  fetchUserSettings() {
    // data
    const currentCustomer = getCurrentCustomerFromLS();
    return ApiwholesaleV2withMgrService.accessGet(
      `/configs/customer/${currentCustomer.id}`
    );
  },

  // fetchUserSettings(params) {
  //   const currentCustomer = getCurrentCustomerFromLS();
  //   const newParams = { ...params };
  //   if (newParams && newParams.filters.from && newParams.filters.to) {
  //     newParams.from = newParams.filters.from;
  //     newParams.to = newParams.filters.to;
  //     delete newParams.filters.from;
  //     delete newParams.filters.to;
  //   }

  //   //https://wholesale.hoteos.net/api/v2
  //   return ApiwholesaleV2withMgrService.accessGet(
  //     `/configs/customer?customer_id=${currentCustomer.id || '0'}&`,
  //     newParams
  //   );
  // },

  fetchUserSettingsSchema() {
    return ApiwholesaleV2withMgrService.accessGet(`/configs/customer/schema`);
  },
  fetchUserSettingsUISchema() {
    return ApiwholesaleV2withMgrService.accessGet(`/configs/customer/uischema`);
  },
  updateUserSettings(data) {
    return ApiwholesaleV2withMgrService.accessPut(`/configs/customer`, data);
  },

  copySettingsToOtherUsers(data) {
    return ApiwholesaleV2withMgrService.accessPut(
      `/configs/customer/copy`,
      data
    );
  },
};
