/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

const STATUS_TO_COLOR = {
  init: 'lime',
  pending: 'cyan',
  error: 'red',

  request_sent: 'blue',
  failed: 'red',
  request_declined: 'orange',

  complete: 'green',
};

export function statusToText(status = '') {
  if (!status) {
    return 'Empty';
  }
  const parts = status.split('_');

  return parts
    .map((part) => {
      return part.substr(0, 1).toUpperCase() + part.substr(1);
    })
    .join(' ');
}

function PrintActivationsStatus({ status, blacklisted_at }) {
  const isHideStatus = useMemo(() => status === '?' && blacklisted_at, [
    status,
    blacklisted_at,
  ]);

  return (
    <>
      {!isHideStatus && (
        <Tag className="mr-small" color={STATUS_TO_COLOR[status]}>
          {statusToText(status)}
        </Tag>
      )}
      {blacklisted_at && <Tag color="grey">Blacklisted</Tag>}
    </>
  );
}

PrintActivationsStatus.propTypes = {
  blacklisted_at: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  status: PropTypes.oneOf([
    'init',
    'pending',
    'error',
    'request_sent',
    'failed',
    'request_declined',
    'complete',
    '?',
  ]).isRequired,
};

PrintActivationsStatus.defaultProps = {
  blacklisted_at: false,
};

export default PrintActivationsStatus;
