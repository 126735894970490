import React from 'react';

import b_ from 'b_';
import { Table } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import DividerSelectedGames from '../../../Components/DividerSelectedGames';
import PrintPrice from '../../../Components/PrintPrice';
import PrintRequestStatus from '../../../Components/PrintRequestStatus';
import PrintUntil from '../../../Components/PrintUntil';
import Price from '../Table/Price';

const { Column } = Table;

const b = b_.lock('PriceListTable');

/** Table to display selected games */
function TableSelected({
  list,
  selected,
  handleOnSelectAll,
  handleOnSelected,
  isLoading,
}) {
  const withEdit = useSelector(
    (state) =>
      (state.core.customer &&
        state.core.customer.perm_price_req) ||
      false,
    shallowEqual
  );
  return (
    <>
      <Table
        bordered
        className="ant-table-white-bg mb"
        rowClassName={() => b('row')}
        loading={isLoading}
        dataSource={list}
        pagination={false}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: selected,
          onSelect: handleOnSelected,
          onSelectAll: handleOnSelectAll,
        }}
      >
        <Column title="Sub ID" dataIndex="sub_id" width={150} />

        <Column title="Name" dataIndex="game" />
        <Column width={150} title="Region" dataIndex="region" />
        <Column
          title="Price, €"
          dataIndex="price"
          width={150}
          className="price-column"
          render={(price, rec) => (
            <Price
              price={price}
              withSale={!!rec.sale_price}
              req_status={rec.req_status}
              base_price={rec.base_price || 100}
              ratio={rec.ratio}
            />
          )}
        />
        <Column
          title="Sale Price"
          width={150}
          className="price-column"
          dataIndex="sale_price"
          render={(text) => (text === 0 ? '-' : <PrintPrice price={text} />)}
        />
        <Column
          title="Until"
          width={250}
          dataIndex="sale_until"
          render={(date) => <PrintUntil date={date} printDiffDays />}
        />
        {withEdit && (
          <Column
            title="Price Request"
            dataIndex="req_status"
            width={150}
            render={(text, product) => (
              <>
                <PrintRequestStatus
                  status={text || '-1'}
                  rejectInfo={product.req_response}
                />
              </>
            )}
          />
        )}
      </Table>
      <DividerSelectedGames />
    </>
  );
}

export default TableSelected;
