import { Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import ListWrapper from '../../HOC/ListWrapper';
import {
  blizzardCancelOrder,
  blizzardCheckCodes,
  blizzardFetchOrderInfo,
  blizzardFetchOrderInfoReset,
  blizzardGetInventoryOrder,
  blizzardOrderedListFetch,
  blizzardOrderedListFetchReset,
  blizzardStopOrder,
  blizzardStopOrderReset,
} from '../../Reducers/blizzard';
import Filters from '../Orders/Filters';
import './styles.scss';
import TableOrdered from '../Orders/TableOrdered';
import UserSettingsService from '../../Services/UserSettingsService';
import { momentToUnixRange, dateRangePeriods } from '../../Utils/date';

/** This is Page */
function BlizzardOrders(props) {
  const {
    list,
    onChange: parentOnChange,
    isLoading,
    pagination,
    setFilters,
    filters,
    fetchList,
    setSorterAndFiltersAndQueryAndPageSize,
  } = props;

  const dispatch = useDispatch();

  const onChange = (a, b, c) =>
    UserSettingsService.onChangeSettings(
      'BlizzardOrder',
      parentOnChange,
      a,
      b,
      c
    );

  useEffect(
    () => {
      const settings = UserSettingsService.getSorterAndFiltersAndQueryAndPageSize(
        'BlizzardOrder'
      );

      const range = momentToUnixRange(dateRangePeriods()['This Week']);

      settings[1] = {
        ...settings[1],
        from: settings[1].from || range[0],
        to: settings[1].to || range[1],
      };

      setSorterAndFiltersAndQueryAndPageSize(...settings);

      return () => {
        dispatch(blizzardOrderedListFetchReset);
        dispatch(blizzardFetchOrderInfoReset());
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const payload = useSelector(
    (state) => state.core.customer.id,
    shallowEqual
  );

  const updateList = useCallback(
    () => fetchList(),
    [dispatch, fetchList]
  );

  useEffect(() => {
    async function update() {
      const response = await updateList();
    }
    update()
  }, [payload]);

  return (
    <>
      <Typography.Title>Blizzard Orders</Typography.Title>
      <Filters
        className="mb"
        fetchList={fetchList}
        isLoading={isLoading}
        value={
          filters && filters.from && filters.to
            ? [filters.from, filters.to]
            : null
        }
        setFilters={(filters) =>
          UserSettingsService.setFilters('BlizzardOrder', setFilters, filters)
        }
      />
      <TableOrdered
        fetch={fetchList}
        list={list}
        isLoading={isLoading}
        pagination={pagination}
        onChange={onChange}
        stateReducer={(state) => state.blizzard}
        checkCodes={blizzardCheckCodes}
        cancelOrder={blizzardCancelOrder}
        getInventoryOrder={blizzardGetInventoryOrder}
        fetchOrderInfo={blizzardFetchOrderInfo}
        stopOrder={blizzardStopOrder}
        stopOrderReset={blizzardStopOrderReset}
      />
    </>
  );
}

export default React.memo(BlizzardOrders)

// export default React.memo(
//   ListWrapper(
//     BlizzardOrders,
//     (state) => state.blizzard.ordered_list,
//     blizzardOrderedListFetch
//   )
// );
