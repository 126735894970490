import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Button, DatePicker, Divider } from 'antd';
import { momentToUnix, unixToMoment } from '../../../../Utils/date';
import './styles.scss';

const { RangePicker } = DatePicker;
const b = b_.lock('FilterUpdateAt');
const defaultValues = [null, null];

function FilterUpdateAt({
  setSelectedKeys,
  confirm,
  selectedKeys,
  clearFilters,
}) {
  const [values, setValues] = useState(defaultValues);

  const onChange = useCallback(
    (value) => {
      if (value.length) {
        setValues([momentToUnix(value[0]), momentToUnix(value[1])]);
        return;
      }
      setValues(defaultValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedKeys]
  );

  const handleOk = useCallback(() => {
    if (values.length && values[0] && values[1]) {
      setSelectedKeys([
        { start: momentToUnix(values[0]), stop: momentToUnix(values[1]) },
      ]);
      confirm();
      return;
    }
    clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (selectedKeys.length) {
      setValues([+selectedKeys[0].start, +selectedKeys[0].stop]);
      return;
    }
    setValues(defaultValues);
  }, [selectedKeys]);

  return (
    <div className={b()}>
      <RangePicker
        showTime
        size="small"
        onChange={onChange}
        value={[
          (values[0] && unixToMoment(values[0])) || null,
          (values[1] && unixToMoment(values[1])) || null,
        ]}
      />
      <Divider className={b('divider')} />
      <div className={b('footer')}>
        <div className={b('footer__buttons')}>
          <Button type="link" onClick={handleOk}>
            Ok
          </Button>
          <Button type="link" onClick={clearFilters}>
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FilterUpdateAt;
