import { Input, Spin, message } from 'antd'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateComment } from '../../../../Reducers/xbox'

const ColumnComment = ({ comment, orderId }) => {

  // const { isLoading } = useSelector((state) => state.xbox.commentOrder, shallowEqual)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();

  const [newComment, setComment] = useState()

  const handleSetComment = (e) => {
    setComment(e.target.value)
  }

  const handleSuccess = (e) => {
    
    if (e.keyCode === 13) {
      setIsLoading(true)
      const commentData = { comment: newComment }
      dispatch(updateComment(commentData, orderId))
      .then(() => {
        message.success(`Comment updated!`)
        setIsLoading(false)
      })
      .catch((err) => {
        message.error(`Failed to update comment!`)
        setIsLoading(false)
      })
  }
  }

  return (
    <>
    {isLoading ?
    <Spin /> :
    <Input 
    style={{border: 'none'}} 
    onKeyDown={handleSuccess} 
    placeholder="Comment and press Enter" 
    value={newComment} 
    defaultValue={comment} 
    onChange={(e) => handleSetComment(e)} 
  />
  
  }
    
    </>
  )
}

export default ColumnComment
