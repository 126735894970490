import b_ from 'b_';
import React from 'react';
import cx from '../../../../Utils/cx';
import './styles.scss';

const b = b_.lock('ColumnOrderedFilled');

function ColumnOrderedFilled({ ordered, filled }) {
  return (
    <div className={b()}>
      <div className={b('count')}>{ordered || 0}</div>
      <div>&nbsp;/&nbsp;</div>
      <div className={cx(b('count'), b('count__filled'))}>{filled || 0}</div>
    </div>
  );
}

export default React.memo(ColumnOrderedFilled);
