import React, { useCallback } from 'react';
import { Button, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { balanceGeneratePDF } from '../../../Reducers/balance';

function PDFButton({ style }) {
  const isLoading = useSelector(
    (state) => state.balance.generatePDF.isLoading,
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(balanceGeneratePDF()).catch((error) => {
      if (error.includes('403 Forbidden')) {
        return message.error('Status 403 Forbidden');
      }
      message.error(error.message || error);
    });
  }, [dispatch]);

  return (
    <Button
      icon="file-pdf"
      size="large"
      style={style}
      onClick={handleClick}
      loading={isLoading}
      type="primary"
    >
      Get PDF invoice
    </Button>
  );
}

PDFButton.propTypes = {
  style: PropTypes.object,
};

PDFButton.defaultProps = {
  style: null,
};

export default React.memo(PDFButton);
