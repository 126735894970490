import { Popover, Tooltip } from 'antd';
import React from 'react';
import ChangeBalanceForm from '../../ChangeBalanceForm';
import Icon from '@ant-design/icons/lib/components/Icon';
import { PlusCircleOutlined } from '@ant-design/icons';

function IncreaseBalance({ id, className }) {
  return (
    <Tooltip placement="bottomRight" title="Increase balance" destroyTooltipOnHide>
      <Popover
        content={<ChangeBalanceForm type="increase" id={id} />}
        title="Increase balance"
        trigger="click"
        placement="topRight"
      >
        <PlusCircleOutlined />
        {/* <Icon type="plus-circle" className={['mr', className, 'cursor-pointer']} /> */}
      </Popover>
    </Tooltip>
  );
};

export default IncreaseBalance;
