import React from 'react';
import { Table } from 'antd';
import { numberToRegion } from '../../../Utils/regions';
import PrintDate from '../../../Components/PrintDate';
import PrintActivationsStatus from '../../../Components/PrintActivationStatus';
import RemoveFromBlacklistButton from '../RemoveFromBlacklistButton';
import FilterUpdateAt from './FilterUpdateAt';
import FilterUpdateAtIcon from './FilterUpdateAt/FilterUpdateAtIcon';
import Icon from '@ant-design/icons/lib/components/Icon';

const { Column } = Table;

function ActivationsTable({
  pagination,
  onChange,
  isLoading,
  list,
  onPagination = false,
  filters,
}) {
  return (
    <Table
      loading={isLoading}
      dataSource={list}
      className="ant-table-white-bg"
      rowKey={(obj) => `code_${obj.code}`}
      bordered
      onChange={(a, b, c) => onChange && onChange(a, b, c)}
      pagination={onPagination ? pagination : false}
    >
      <Column
        title="Code"
        dataIndex="code"
        width="260px"
        render={(code) => (
          <div className="flex-left">
            <Icon type="barcode" className="mr-small" />
            <small>{code}</small>
          </div>
        )}
      />
      <Column
        title="Update At"
        filterIcon={(filtered) => FilterUpdateAtIcon(filtered)}
        filterDropdown={(props) => <FilterUpdateAt {...props} />}
        filteredValue={filters.gm_updated || []}
        dataIndex="gm_updated"
        render={(date) => (date ? <PrintDate withTime date={date} /> : '-')}
      />
      <Column title="Sub" dataIndex="sub_id" />
      <Column title="Name" dataIndex="game" render={(game) => game || '-'} />
      <Column
        title="Region"
        dataIndex="region"
        render={(num) => numberToRegion[num] || '-'}
      />
      <Column
        title="Status"
        dataIndex="gm_status"
        filteredValue={filters.gm_status || []}
        filters={[
          {
            text: 'Failed',
            value: 'failed',
          },
          {
            text: 'Request Declined',
            value: 'request_declined',
          },
          {
            text: '?',
            value: '?',
          },
          {
            text: 'Blacklisted',
            value: 'blacklisted',
          },
        ]}
        render={(status, item) => {
          return (
            <>
              <PrintActivationsStatus
                status={status}
                blacklisted_at={item.blacklisted_at}
              />
              {item.blacklisted_at && (
                <RemoveFromBlacklistButton giftcode={item.code} />
              )}
            </>
          );
        }}
      />
    </Table>
  );
}

export default ActivationsTable;
