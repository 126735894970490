import ApiService from './ApiService';

function prepareCodes(codes) {
  return codes
    .split(/[,\n ]/)
    .map((code) => code.trim())
    .filter((code) => code);
}

export default {
  fetchList(params, codes) {
    if (params)
      return ApiService.get(
        '/codes/search',
        params
      ).then(({ total, page }) => ({ total, list: page }));
    return ApiService.post('/codes/search', prepareCodes(codes));
  },
};
