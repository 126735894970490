import PriceListService from '../Services/PriceListService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const priceListSearchCreateReset = createAction('priceList/search-create_RESET')

export const priceListFetch = createAction('priceList/fetch', (params) => ({
  payload: PriceListService.fetchList(params)
}))

export const priceListGetLinks = createAction('priceList/get-links', (ids, amount) => ({
  payload: PriceListService.getLinks(ids, amount)
}))

export const priceListStopSale = createAction('priceList/stop-sale', (ids) => ({
  payload: PriceListService.stopSale(ids)
}))

export const priceListUpdatePrice = createAction('priceList/update-price', (id, price) => ({
  payload: PriceListService.updatePrice(id, price)
}))

export const priceListSearchCreate = createAction('priceList/search-create', (query, regions) => ({
  payload: PriceListService.searchCreate(query, regions)
}))

export const priceListCreate = createAction('priceList/create', (ids) => ({
  payload: PriceListService.create(ids)
}))

export const priceListRemoveRequests = createAction('priceList/remove-request', (ids) => ({
  payload: PriceListService.removeRequests(ids)
}))

export const priceListGetGamesData = createAction('priceList/get-games-data', (ids) => ({
  payload: PriceListService.getGamesData(ids)
}))


const initState = {
  list: getDefaultState(),
  getGamesLinks: getDefaultState(),
  stopSale: getDefaultState(),
  updatePrice: getDefaultState(),
  searchCreate: getDefaultState(),
  create: getDefaultState(),
  removeRequests: getDefaultState(),
  gamesData: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(priceListFetch, 'list'),
  ...getDefaultHandler(priceListGetLinks, 'getGamesLinks'),
  ...getDefaultHandler(priceListStopSale, 'stopSale'),
  ...getDefaultHandler(priceListUpdatePrice, 'updatePrice'),
  ...getDefaultHandler(priceListSearchCreate, 'searchCreate'),
  ...getDefaultHandler(priceListCreate, 'create'),
  ...getDefaultHandler(priceListRemoveRequests, 'removeRequests'),
  ...getDefaultHandler(priceListGetGamesData, 'gamesData')
})
