import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import { renderRoutes } from 'react-router-config';
import { Layout } from 'antd';
import Header from '../Header';
import './styles.scss';
import { Outlet, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const { Content } = Layout;
const b = b_.lock('MainLayout');

function MainLayout() {

  const [manager, setManager] = useState(null)

  const token = localStorage.getItem('ni-dash_access_token')
  const tokenData = jwtDecode(token)

  useEffect(() => {
    if (tokenData["cognito:groups"]) {
      if (tokenData["cognito:groups"]
      .some((group) => 
        group.includes('manager') ||
        group.includes('admin') || 
        group.includes('cargo') || 
        group.includes('manager') || 
        group.includes('support'))) {
          setManager(true)
      } else {
        setManager(false)
      }
    } else {
      setManager(false)
    }
  }, [token])


  const location = useLocation()
  return (
    <Layout className={b()}>
      <Header location={location} isManager={manager} />
      <Content className={b('content')}>
          <Outlet context={manager} />
        </Content>
    </Layout>
  );
}

export default MainLayout;
