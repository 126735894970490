import React, { useCallback } from 'react';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { priceListGetGamesData } from '../../../Reducers/priceList';

function GetGameData({ ids }) {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.priceList.gamesData.isLoading,
    shallowEqual
  );

  const handleClick = useCallback(() => {
    dispatch(priceListGetGamesData(ids)).catch((error) => {
      message.error(error);
    });
  }, [dispatch, ids]);

  return (
    <Button
      onClick={handleClick}
      loading={loading}
      type="dashed"
      size="large"
      icon="download"
    >
      Get Data of {ids.length || 'All'} Games
    </Button>
  );
}

GetGameData.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(GetGameData);
