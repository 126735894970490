import React, { useCallback, useState } from 'react';
import { Button, Col, Input, Row } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import ShowSelectedGamesButton from '../../Components/ShowSelectedGamesButton';
import TablePriceList from './Table';
// import ListWrapper from '../../HOC/ListWrapper';
import { priceListFetch } from '../../Reducers/priceList';
import BottomButtons from './BottomButtons';
import UpdatePriceModal from './UpdatePriceModal';
import GetGameData from './GetGameData';
import useBoolean from '../../Utils/useBoolean';
import AddProduct from './Add';
import TableSelected from './TableSelected';
import useUpdateEffect from '../../Utils/useUpdateEffect';

const { Search } = Input;

/** This is Page */
function PriceListSteam({
  list,
  isLoading,
  onChange,
  sorter,
  pagination,
  filters,
  query,
  onSearch,
  fetchList,
}) {
  const [infoSelectedRows, setInfoSelectedRows] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { value: isShowSelected, toggleValue: toggleShowSelected } = useBoolean(
    false
  );
  const { value: isOpenAdd, setFalse: closeAdd, setTrue: openAdd } = useBoolean(
    false
  );

  const {
    getLinksPayload,
    stopSalePayload,
    updatePricePayload,
    addPayload,
    removeRequestsPayload,
  } = useSelector(
    (state) => ({
      getLinksPayload: state.priceList.getGamesLinks.payload,
      stopSalePayload: state.priceList.stopSale.payload,
      updatePricePayload: state.priceList.updatePrice.payload,
      addPayload: state.priceList.create.payload,
      removeRequestsPayload: state.priceList.removeRequests.payload,
    }),
    shallowEqual
  );

  const handleOnSelectAll = useCallback(
    (status, _, items) => {
      const copy = { ...infoSelectedRows };

      items.forEach((item) => {
        const id = `${item.sub_id}:${item.region}`;
        if (status) {
          copy[id] = item;
        } else {
          delete copy[id];
        }
      });

      setInfoSelectedRows(copy);
    },
    [infoSelectedRows]
  );

  const handleOnSelected = useCallback(
    (item, status) => {
      const copy = { ...infoSelectedRows };
      const id = `${item.sub_id}:${item.region}`;

      if (status) {
        copy[id] = item;
      } else {
        delete copy[id];
      }

      setInfoSelectedRows(copy);
    },
    [infoSelectedRows]
  );

  const updateData = useCallback(
    (list) => {
      const keys = Object.keys(infoSelectedRows);
      const copy = { ...infoSelectedRows };
      let needUpdate = false;
      list.forEach((item) => {
        const id = `${item.sub_id}:${item.region}`;
        if (keys.includes(id)) {
          needUpdate = true;
          copy[id] = item;
        }
      });
      needUpdate && setInfoSelectedRows(copy);
    },
    [infoSelectedRows]
  );

  useUpdateEffect(() => {
    !isLoading && fetchList();
  }, [
    getLinksPayload,
    stopSalePayload,
    updatePricePayload,
    addPayload,
    fetchList,
    removeRequestsPayload,
  ]);

  useUpdateEffect(() => {
    updateData(list);
  }, [list]);

  return (
    <>
      <Title level={1}>
        Price List Steam
        <div style={{ float: 'right' }}>
          <Button
            className="mr-small"
            onClick={openAdd}
            size="large"
            icon="plus"
            type="primary"
          >
            Add
          </Button>
          <GetGameData ids={Object.keys(infoSelectedRows)} />
        </div>
      </Title>
      <Row gutter={16}>
        <Col span={24}>
          <ShowSelectedGamesButton
            isShowSelected={isShowSelected}
            keys={Object.keys(infoSelectedRows)}
            toggleShowSelected={toggleShowSelected}
          />
          {isShowSelected && (
            <TableSelected
              list={Object.values(infoSelectedRows)}
              selected={Object.keys(infoSelectedRows)}
              isLoading={isLoading}
              handleOnSelectAll={handleOnSelectAll}
              handleOnSelected={handleOnSelected}
            />
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Search
            placeholder="Name"
            className="mb"
            size="large"
            defaultValue={query}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <TablePriceList
        list={list}
        isLoading={isLoading}
        onChange={onChange}
        sorter={sorter}
        pagination={pagination}
        filters={filters}
        selected={Object.keys(infoSelectedRows)}
        setSelectedProduct={setSelectedProduct}
        handleOnSelectAll={handleOnSelectAll}
        handleOnSelected={handleOnSelected}
      />
      {!!Object.keys(infoSelectedRows).length && (
        <BottomButtons ids={Object.keys(infoSelectedRows)} />
      )}
      <UpdatePriceModal
        close={() => setSelectedProduct(null)}
        product={selectedProduct}
      />
      <AddProduct close={closeAdd} isOpen={isOpenAdd} />
    </>
  );
}

export default PriceListSteam

// export default ListWrapper(
//   PriceListSteam,
//   (store) => store.priceList.list,
//   priceListFetch
// );
