import BalanceService from '../Services/BalanceService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;



export const balanceFetchListReset = createAction('balance/fetch-list_RESET')

export const balanceFetchList = createAction('balance/fetch-list', (dates) => ({
  payload: BalanceService.fetchList(dates),
}))

export const balanceFetchListNonMgr = createAction('balance/fetch-list_account', (dates) => ({
  payload: BalanceService.fetchListNonMgr(dates),
}))

export const balanceGeneratePDF = createAction('balance/generate-pdf', () => ({
  payload: BalanceService.generatePDF(),
}))

export const balanceGenerateCSV = createAction('balance/generate-csv', () => ({
  payload: BalanceService.generateCSV(),
}))

export const customersChangeBalance = createAction('balance/change', (params) => ({
  payload: BalanceService.changeBalance(params),
}))

const initState = {
  list: getDefaultState(),
  list_account: getDefaultState(),
  generatePDF: getDefaultState(),
  generateCSV: getDefaultState(),
  changeBalance: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(balanceFetchList, 'list'),
  ...getDefaultHandler(balanceFetchListNonMgr, 'list_account'),
  ...getDefaultHandler(balanceGeneratePDF, 'generatePDF'),
  ...getDefaultHandler(balanceGenerateCSV, 'generateCSV'),
  ...getDefaultHandler(customersChangeBalance, 'changeBalance'),
})
