import qs from 'qs';
import { frc } from 'front-end-common/dist/bundle';
import TokenService from './TokenService';

const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
} = process.env;

const cognitoCredType = {
  CLIENT_ID,
  AUTH_URL,
};

export function prepareQueryString(params) {
  const updParams = Object.keys(params)
    .filter((key) => params[key] !== null)
    .reduce((acc, cur) => ({ ...acc, [cur]: params[cur] }), {});

  return qs.stringify(updParams, { encode: false, arrayFormat: 'brackets' });
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

const API_PREFIX = process.env.REACT_APP_API_PREFIX;
const { ApiService } = frc.services;

const wholesaleV1 = new ApiService(
  `${API_PREFIX}/api/v1`,
  TokenService,
  cognitoCredType
);

const wholesaleV2 = new ApiService(
  `${API_PREFIX}/api/v2`,
  TokenService,
  cognitoCredType
);

const wholesaleV2WithoutPrefix = new ApiService(
  `/api/v2`,
  TokenService,
  cognitoCredType
);

const wholesaleV2withMgr = new ApiService(
  `${API_PREFIX}/api/v2/mgr`,
  TokenService,
  cognitoCredType
);

const wholesaleV2withAccount = new ApiService(
  `${API_PREFIX}/api/v2/account`,
  TokenService,
  cognitoCredType
);

const merchantV2 = new ApiService(
  `/merchant/api/v2`,
  TokenService,
  cognitoCredType
);

export {
  wholesaleV1,
  wholesaleV2,
  wholesaleV2withMgr,
  merchantV2,
  wholesaleV2WithoutPrefix,
  wholesaleV2withAccount
};

export default wholesaleV1;
