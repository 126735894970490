import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FixedBottom from '../../../Components/FixedBottom';
import GetLinksModal from '../../../Components/GetLinksModal';
import useBoolean from '../../../Utils/useBoolean';
import {
  priceListGetLinks,
  priceListRemoveRequests,
  priceListStopSale,
} from '../../../Reducers/priceList';
import './styles.scss';

const b = b_.lock('BottomButtonsSteam');

function GetGamesLink({ ids }) {
  const loading = useSelector(
    (state) => state.priceList.getGamesLinks.isLoading
  );
  const dispatch = useDispatch();

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);
  const [number, setNumber] = useState(50);

  const onOk = useCallback(() => {
    dispatch(priceListGetLinks(ids, number))
      .then(() => {
        close();
        setNumber(50);
      })
      .catch((error) => {
        message.error(error);
      });
  }, [ids, number, dispatch, close]);

  return (
    <>
      <Button
        className={b('button')}
        onClick={open}
        type="primary"
        size="large"
        icon="cloud-download"
      >
        Get Games Links
      </Button>
      <GetLinksModal
        close={close}
        number={number}
        onOk={onOk}
        setNumber={setNumber}
        visible={visible}
        loading={loading}
      />
    </>
  );
}

GetGamesLink.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function StopSale({ ids }) {
  const dispatch = useDispatch();

  const confirm = useCallback(
    () =>
      Modal.confirm({
        onOk() {
          return dispatch(priceListStopSale(ids))
            .then(() => {
              message.success('Sale of Games has been stopped!');
            })
            .catch((error) => {
              message.error(error);
            });
        },
        title: 'Confirm',
        content: `Are you want to pause the sale of ${ids.length} games?`,
        maskClosable: false,
      }),
    [ids, dispatch]
  );

  return (
    <Button
      icon="stop"
      size="large"
      className={b('button')}
      onClick={confirm}
      type="danger"
    >
      Stop Sale
    </Button>
  );
}

StopSale.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function RemoveRequests({ ids }) {
  const dispatch = useDispatch();

  const confirm = useCallback(
    () =>
      Modal.confirm({
        onOk() {
          return dispatch(priceListRemoveRequests(ids))
            .then(() => {
              message.success('Requests has been removed!');
            })
            .catch((error) => {
              message.error(error);
            });
        },
        title: 'Confirm',
        content: `Are you want to remove requests for ${ids.length} games?`,
        maskClosable: false,
      }),
    [ids, dispatch]
  );

  return (
    <Button
      icon="delete"
      size="large"
      className={b('button')}
      onClick={confirm}
    >
      Remove Requests
    </Button>
  );
}

RemoveRequests.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function BottomButtons({ ids }) {
  return (
    <FixedBottom>
      <div className={b()}>
        <StopSale ids={ids} />
        <GetGamesLink ids={ids} />
        <RemoveRequests ids={ids} />
      </div>
    </FixedBottom>
  );
}

BottomButtons.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BottomButtons;
