import ApiService from './ApiService';
import { downloadFile } from '../Utils/apiHelper';

function idToObject(id) {
  const [sub_id, region] = id.split(':');

  return {
    sub_id: +sub_id,
    region,
  };
}

function prepareData(items) {
  return items.map(function(item) {
    return {
      ...item,
      id: `${item.sub_id}:${item.region}`,
    };
  });
}

export default {
  fetchList(params) {
    return ApiService.get('/custprices', params).then((data) => ({
      list: prepareData(data.page),
      total: data.total,
    }));
  },
  searchCreate(query, region) {
    const q = query.split(/\s+/).join(',');
    return ApiService.get('/baseprices', { q, filters: { region } }).then(
      prepareData
    );
  },
  create(ids) {
    return ApiService.put('/custprices', ids.map(idToObject)).then(() => ({}));
  },
  getLinks(ids, count) {
    return ApiService.post(
      `/gamelinks?count=${count}`,
      ids.map((id) => ({ ...idToObject(id) }))
    ).then(downloadFile);
  },
  stopSale(ids) {
    return ApiService.delete('/custprices', ids.map(idToObject));
  },
  updatePrice(id, price) {
    return ApiService.put('/custrequests', [{ ...idToObject(id), price }]);
  },
  removeRequests(ids) {
    return ApiService.put('/custrequests', ids.map(idToObject));
  },
  getGamesData(ids) {
    return ApiService.post('/gamedata', ids.map(idToObject)).then(downloadFile);
  },
};
