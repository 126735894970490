import {
  wholesaleV2 as ApiWholesaleV2Service,
  wholesaleV2withMgr as ApiwholesaleV2withMgrService,
  wholesaleV2withAccount as wholesaleV2withAccountService
} from './ApiService';

const CURRENT_CUSTOMER_LS_KEY = '__current_customer__';

export function setCurrentCustomerToLS(customer) {
  localStorage.setItem(CURRENT_CUSTOMER_LS_KEY, JSON.stringify(customer));
}

export function getCurrentCustomerFromLS() {
  return JSON.parse(localStorage.getItem(CURRENT_CUSTOMER_LS_KEY)) || {};
}

export default {
  fetchCustomers() {
    return ApiwholesaleV2withMgrService.accessGet('/customers');
  },

  fetchCustomersSelected() {
    return ApiwholesaleV2withMgrService.accessGet('/customers/selected');
  },

  fetchCustomersSettings() {
    return ApiwholesaleV2withMgrService.accessGet('/user/settings');
  },

  changeCustomersSettings(data) {
    return ApiwholesaleV2withMgrService.accessPut('/user/settings', data);
  },

  fetchAccountData() {
    return wholesaleV2withAccountService.accessGet('/details');
  },

  setCustomerCreditLimit(data) {
    return ApiwholesaleV2withMgrService.patch('/customer/creditlimit', data)
  }
};

