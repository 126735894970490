import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useOutletContext } from 'react-router-dom';

function RegionSelect({
  className,
  value,
  onChange,
  stateReducer,
  fetchRegions,
}) {

  const isManager = useOutletContext();
  const { payload, isLoading } = useSelector(
    (state) => stateReducer(state).regions,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && payload === null && isManager !== null) {
      dispatch(fetchRegions());
    }
  }, [dispatch, isManager]);

  return (
    <Select
      loading={isLoading}
      value={value}
      onChange={onChange}
      className={className}
      // size="small"
      style={{ width: '100%' }}
      placeholder="Select region"
      allowClear
    >
      {payload &&
        payload.map((region) => (
          <Select.Option key={region} value={region}>
            {region}
          </Select.Option>
        ))}
    </Select>
  );
}

RegionSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  stateReducer: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
};

RegionSelect.defaultProps = {
  className: '',
  value: undefined,
};

export default RegionSelect;
