import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const bottom = document.getElementById('portal-bottom');

function FixedBottom({ children }) {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    bottom.appendChild(el);

    return () => {
      bottom.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
}

export default FixedBottom;
